import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  width: 100%;
  width: ${props => props.className === 'full-width' ? "100%" : "1024px"};
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 50px;
  margin: 0 auto;

  @media screen and (max-device-width: 480px) {
    height: 80px;
    padding: 0 15px;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 4px 14px -9px rgba(0, 0, 0, 0.59);
    box-shadow: 0px 4px 14px -9px rgba(0, 0, 0, 0.59);
    max-width: 100% !important;
  }

  .voltar {
    display: flex;
    align-items: center;
    vertical-align: middle;

    >a img {
      width: 15px;
    }

    > p {
      margin: 0;
      margin-left: 10px;
      line-height: 80px;
      vertical-align: middle;

      a {
        color: #989898;
        text-decoration: none;
        color: #000;
      }
    }
  }
`;

export const ContentProfile = styled.div`
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    cursor: pointer;
  
    > div:nth-child(1) {
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      align-self: center;
      text-align: right;

      p {
        text-align: right;
        padding-right: 15px;
        margin-bottom: 0;
        font-size: 14px;
        padding-top: 4px;
      }
      img {
        margin-right: 16px;
        margin-top: -5px;
      }
    }
    > div:nth-child(2) {
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      align-self: center;
    }
`;

export const Logo = styled.div`
  width: 100%;
  max-width: 120px;

  @media screen and (max-device-width: 480px) {
    max-width: 100px;
  }

  img {
    width: 100%;
  }
`;

export const Cadastro = styled.div`
  .btn-register,
  .btn-entrar {
    color: #989898;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: none;

    @media screen and (max-device-width: 480px) {
      font-size: 14px;
    }
  }
  .btn-entrar {
    margin-left: 43px;
    color: #fff;
    padding: 8px 34px;
    background-color: #442c75;
    border-radius: 5px;

    @media screen and (max-device-width: 480px) {
      margin-left: 15px;
    }
  }
`;

export const Profile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 9;

  .avatar {
    width: 40px;
    border: 1px solid #e0a134;
    border-radius: 25px;
    padding: 1px;
  }

  .arrow {
    width: 12px;
    align-self: flex-end;
    margin-left: 2px;
    margin-bottom: 5px;
  }
  > ul {
    margin-bottom: 0;
  }

  > ul li {
    cursor: pointer;
  }

  > ul li div {
    display: flex;
    align-items: center;
    justify-content: center;

    > p {
      font-size: 13px;
      font-family: 'OpenSans';
      color: #606060;
    }
  }
`;

export const BoxLogin = styled.div`
  width: 150px;
  height: 200px;
  height: 0;
  overflow: hidden;
  position: absolute;
  top: 45px;
  right: 0;
  transition: all 450ms;
  z-index: 2;

  > div {
    border: 1px solid #eeeeee;
    border-radius: 5px;
    margin: 5px 0;
    background-color: #ffffff;
    width: 100%;

    > ul {
    margin-bottom: 0;
    padding-left: 0;
  }
  }

  > div ul {
    width: 100%;
  }

  > p {
    font-size: 10px;
  }

  > div ul li {
    width: 100%;
    color: #767676;
    padding: 10px;
    border-bottom: 1px solid #eeeeee;
    font-size: 14px;
  }

  .li-sair {
    border: none;
  }
`;