import { Container, Content, ImgQuemSomos, TextPre } from './style';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const Privacidade: React.FC = () => {
  return (
    <Container>
      <Header />
      <Content>
        <h1>POLÍTICA DE PRIVACIDADE E TERMOS DE USO</h1>
        <p dir="ltr">
          <span>
            A JOGA LA COMUNICA&Ccedil;&Otilde;ES EM INTERNET LTDA ME &eacute;
            respons&aacute;vel pelo site:{' '}
          </span>
          <a href="https://linktree.com.br/">https://joga.la/</a>
        </p>
        <p dir="ltr">
          <span>
            A JOGA LA COMUNICA&Ccedil;&Otilde;ES EM INTERNET LTDA ME recomenda
            que esta Pol&iacute;tica de Privacidade e os Termos de Uso sejam
            lidos cuidadosamente antes da utiliza&ccedil;&atilde;o dos
            servi&ccedil;os prestados pelo site https://joga.la.
          </span>
        </p>
        <p dir="ltr">
          <span>Ao se cadastrar no site </span>
          <a href="https://linktree.com.br/">
            <span>https://joga.la</span>
          </a>
          <span>
            {' '}
            , voc&ecirc; estar&aacute; concordando com os termos e
            condi&ccedil;&otilde;es de uso e caso n&atilde;o esteja de acordo
            voc&ecirc; n&atilde;o poder&aacute; usar os nossos servi&ccedil;os.
          </span>
        </p>
        <p dir="ltr">
          <span>
            O usu&aacute;rio ser&aacute; chamado a partir de agora de
            cliente/usu&aacute;rio.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Para utilizar o nosso servi&ccedil;o o usu&aacute;rio se
            responsabiliza pelas configura&ccedil;&otilde;es da sua conta , isto
            &eacute;: configura&ccedil;&atilde;o da conta, links,
            conte&uacute;do, bot&otilde;es, dados pessoais,
            configura&ccedil;&atilde;o do perfil, configura&ccedil;&atilde;o do
            Analytics e do Pixel do Facebook.
          </span>
        </p>
        <p dir="ltr">
          <span>
            A JOGA LA COMUNICA&Ccedil;&Otilde;ES EM INTERNET LTDA ME entende que
            a coleta de dados de seus clientes &eacute; importante,
            necess&aacute;ria e sigilosa para realizar as
            opera&ccedil;&otilde;es em seu site e ser&atilde;o utilizadas para
            proporcionar uma melhor experi&ecirc;ncia de
            navega&ccedil;&atilde;o, por isso, apresentamos nossa
            pol&iacute;tica de privacidade e termos e condi&ccedil;&otilde;es de
            uso e de cookies que tem por objetivo esclarecer o uso dessas
            informa&ccedil;&otilde;es.
          </span>
        </p>
        <p dir="ltr">
          <span>Ao visitar a p&aacute;gina </span>
          <a href="https://linktree.com.br/">
            <span>https://joga.la</span>
          </a>
          <span>
            {' '}
            voc&ecirc; aceita as pr&aacute;ticas descritas na pol&iacute;tica de
            privacidade, seguran&ccedil;a e nos termos e condi&ccedil;&otilde;es
            de uso.
          </span>
        </p>
        <p dir="ltr">
          <span>
            &Eacute; de responsabilidade do usu&aacute;rio manter atualizado o
            seu email e o seu n&uacute;mero do celular.
          </span>
        </p>
        <p dir="ltr">
          <span>
            O usu&aacute;rio dever&aacute; estar de acordo com as regras de
            utiliza&ccedil;&atilde;o e as pol&iacute;ticas de privacidade para
            poder usar o nosso servi&ccedil;o.
          </span>
        </p>
        <p dir="ltr">
          <span>
            &Eacute; de total responsabilidade do usu&aacute;rio(a) o uso da
            nossa ferramenta, onde o usu&aacute;rio assume os riscos de
            utiliza&ccedil;&atilde;o e divulga&ccedil;&atilde;o dos links.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Ao contratar um plano, os valores ser&atilde;o cobrados independente
            do uso pelo cliente. Ao contratar o plano o cliente dever&aacute;
            configurar seus links, bot&otilde;es e conte&uacute;do, e em caso de
            d&uacute;vidas dever&aacute; entrar em contato com nosso suporte
            -&gt; contato@joga.la ou atrav&eacute;s do Whatsapp: +55 21
            96940-9539
          </span>
        </p>
        <p dir="ltr">
          <span>INFORMA&Ccedil;&Otilde;ES CADASTRAIS</span>
        </p>
        <p dir="ltr">
          <span>
            Para usar os nossos servi&ccedil;os, ser&aacute; necess&aacute;ria a
            cria&ccedil;&atilde;o de uma conta em nosso sistema. Voc&ecirc;
            dever&aacute; fornecer informa&ccedil;&otilde;es pessoais completas,
            verdadeiras e se comprometer em mant&ecirc;-las atualizadas para que
            possamos enviar promo&ccedil;&otilde;es via e-mail,
            notifica&ccedil;&otilde;es Push ou mensagens de texto via SMS.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Podemos tamb&eacute;m obter informa&ccedil;&otilde;es atrav&eacute;s
            de terceiros, como centros de cr&eacute;dito e servi&ccedil;os de
            verifica&ccedil;&atilde;o de identidade para preven&ccedil;&atilde;o
            &agrave; fraudes.
          </span>
        </p>
        <p dir="ltr">
          <span>DADOS COLETADOS:</span>
        </p>
        <p dir="ltr">
          <span>
            &ndash; Nome, email, telefones de contato, CPF, RG, g&ecirc;nero,
            data de nascimento.
          </span>
        </p>
        <p dir="ltr">
          <span>VERACIDADE DAS INFORMA&Ccedil;&Otilde;ES FORNECIDAS</span>
        </p>
        <p dir="ltr">
          <span>
            As informa&ccedil;&otilde;es fornecidas e enviadas s&atilde;o de
            responsabilidade dos usu&aacute;rios do nosso site. O{' '}
          </span>
          <a href="https://linktree.com.br/">
            <span>https://joga.la</span>
          </a>
          <span>
            {' '}
            n&atilde;o se responsabiliza por informa&ccedil;&otilde;es
            fornecidas erradas ou incompletas que prejudiquem o acesso &agrave;
            nossa plataforma.
          </span>
        </p>
        <p dir="ltr">
          <span>
            CADASTRAMENTO, CONTROLE DE DADOS DO CLIENTE E CANCELAMENTO
          </span>
        </p>
        <p dir="ltr">
          <span>Cadastramento</span>
        </p>
        <p dir="ltr">
          <span>
            O cliente tem a liberdade de escolher as informa&ccedil;&otilde;es
            que quer fornecer ao site{' '}
          </span>
          <a href="https://linktree.com.br/">
            <span>https://joga.la</span>
          </a>
          <span>
            {' '}
            entretanto, algumas delas s&atilde;o essenciais para a
            conclus&atilde;o do cadastro e uma melhor experi&ecirc;ncia de
            navega&ccedil;&atilde;o no sistema.
          </span>
        </p>
        <p dir="ltr">
          <span>
            No ato do cadastramento ou preenchimento de seu email, diretamente
            no rodap&eacute; dos sites ou em outro qualquer sistema de captura
            de email, o cliente opta por receber regularmente informativos e
            promo&ccedil;&otilde;es por email, SMS, notifica&ccedil;&otilde;es
            push e WhatsApp.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Ao se cadastrar no site ser&aacute; necess&aacute;rio a
            inclus&atilde;o de um login e senha, e estes dever&atilde;o ser
            guardados em local seguro para evitar fraudes.
          </span>
        </p>
        <p dir="ltr">
          <span>Controle de dados</span>
        </p>
        <p dir="ltr">
          <span>
            Podemos utilizar seus dados pessoais e email para
            divulga&ccedil;&atilde;o de nossos produtos e servi&ccedil;os. Caso
            voc&ecirc; n&atilde;o concorde, envie um email solicitando o
            cancelamento para contato@joga.la
          </span>
        </p>
        <p dir="ltr">
          <span>Cancelamento e descadastramento (&ldquo;Opt-out&rdquo;)</span>
        </p>
        <p dir="ltr">
          <span>
            O cliente poder&aacute; excluir a sua conta a qualquer momento,
            bastando estar logado -&gt; painel de controle &gt;
            configura&ccedil;&atilde;o de conta &gt; excluir conta.
          </span>
        </p>
        <p dir="ltr">
          <span>
            OBS. Ap&oacute;s a sua exclus&atilde;o n&atilde;o poderemos mais
            recuperar os dados da conta exclu&iacute;da.
          </span>
        </p>
        <p dir="ltr">
          <span>
            O cliente pode a qualquer momento solicitar a exclus&atilde;o total
            ou parcial dos seus dados e, para efetivar essa
            solicita&ccedil;&atilde;o, basta enviar uma mensagem para o email:
            contato@joga.la Tamb&eacute;m &eacute; importante ressaltar que, por
            uma quest&atilde;o de seguran&ccedil;a, os dados e
            informa&ccedil;&otilde;es das contas exclu&iacute;das poder&atilde;o
            ficar guardadas em nosso servidor por um per&iacute;odo de
            at&eacute; 5 anos.
          </span>
        </p>
        <p dir="ltr">
          <span>FICA PROIBIDO</span>
        </p>
        <p dir="ltr">
          <span>&ndash; Falsificar documentos.</span>
        </p>
        <p dir="ltr">
          <span>&ndash; Informar dados falsos ou incompletos.</span>
        </p>
        <p dir="ltr">
          <span>
            &ndash; Violar leis federais, estaduais, municipais, e as leis
            internacionais.
          </span>
        </p>
        <p dir="ltr">
          <span>
            &ndash; Divulgar conte&uacute;do com direito autoral sem
            autoriza&ccedil;&atilde;o pr&eacute;via e escrita.
          </span>
        </p>
        <p dir="ltr">
          <span>
            &ndash; Enviar arquivos com v&iacute;rus para nossos servidores.
          </span>
        </p>
        <p dir="ltr">
          <span>
            &ndash; Fica proibido a reprodu&ccedil;&atilde;o ou c&oacute;pia
            parcial ou total do conte&uacute;do da plataforma joga.la, ficando
            sujeito &agrave;s penalidades legais.
          </span>
        </p>
        <p dir="ltr">
          <span>
            &ndash; A utiliza&ccedil;&atilde;o de nossa marca ou logomarca sem
            aviso pr&eacute;vio.
          </span>
        </p>
        <p dir="ltr">
          <span>&ndash; Associar a nossa marca a outra empresa..</span>
        </p>
        <p dir="ltr">
          <span>
            &ndash; Fazer hiperlink para nosso site sem
            autoriza&ccedil;&atilde;o expressa pr&eacute;via e escrita de um
            representante de nossa empresa.
          </span>
        </p>
        <p dir="ltr">
          <span>_ Fazer SPAN utilizando o link fornecido pelo nosso site.</span>
        </p>
        <p dir="ltr">
          <span>
            OBS: Caso seja constatado alguma irregularidade a conta ser&aacute;
            exclu&iacute;da e o cliente n&atilde;o ter&aacute; direito de
            indeniza&ccedil;&atilde;o por uso indevido da plataforma.
          </span>
        </p>
        <p dir="ltr">
          <span>
            IDADE M&Iacute;NIMA PARA PODER ACESSAR E ASSINAR NOSSOS PLANOS
          </span>
        </p>
        <p dir="ltr">
          <span>
            Para ter acesso aos servi&ccedil;os da plataforma joga.la, o
            cliente/usu&aacute;rio deve possuir idade m&iacute;nima de 18 anos.
          </span>
        </p>
        <p dir="ltr">
          <span>
            O cliente/usu&aacute;rio que n&atilde;o atenda esta
            condi&ccedil;&atilde;o ter&aacute; sua conta exclu&iacute;da
            imediatamente.
          </span>
        </p>
        <p dir="ltr">
          <span>PRAZO DE VALIDADE PARA O TESTE GR&Aacute;TIS</span>
        </p>
        <p dir="ltr">
          <span>
            O prazo de teste gr&aacute;tis poder&aacute; ser alterado ou
            cancelado a qualquer momento e sem aviso pr&eacute;vio. Ficamos
            isentos de qualquer responsabilidade em rela&ccedil;&atilde;o ao
            cancelamento e mudan&ccedil;as em nossa pol&iacute;tica comercial.
          </span>
        </p>
        <p dir="ltr">
          <span>ALTERA&Ccedil;&Atilde;O DE TAXAS E PAGAMENTOS</span>
        </p>
        <p dir="ltr">
          <span>
            A JOGA LA COMUNICA&Ccedil;&Otilde;ES EM INTERNET LTDA ME
            poder&aacute; alterar os valores das taxas e assinatura dos seus
            planos sem aviso pr&eacute;vio. Os novos valores ou taxas entraram
            em vigor no final do per&iacute;odo contratado.
          </span>
        </p>
        <p dir="ltr">
          <span>FORMAS DE PAGAMENTO</span>
        </p>
        <p dir="ltr">
          <span>
            Os nossos servi&ccedil;os poder&atilde;o ser pagos com cart&atilde;o
            de cr&eacute;dito e usaremos os melhores meios de pagamento do
            mercado online como: Stripe, Ebanx, PagSeuro ou Mercado Pago.
          </span>
        </p>
        <p dir="ltr">
          <span>COBRAN&Ccedil;A POR TERCEIROS</span>
        </p>
        <p dir="ltr">
          <span>
            N&atilde;o disponibilizamos ou terceirizamos a cobran&ccedil;a de
            assinatura ou taxas por terceiros.
          </span>
        </p>
        <p dir="ltr">
          <span>N&Atilde;O pague a estranhos os valores das mensalidade.</span>
        </p>
        <p dir="ltr">
          <span>
            MUDAN&Ccedil;AS E ALTERA&Ccedil;&Atilde;O NA PLATAFORMA joga.la
          </span>
        </p>
        <p dir="ltr">
          <span>
            A JOGA LA COMUNICA&Ccedil;&Otilde;ES EM INTERNET LTDA ME
            ficar&aacute; isenta de qualquer responsabilidade caso haja a
            necessidade de recadastramento de dados e informa&ccedil;&otilde;es
            pelo cliente/usu&aacute;rio em nossa plataforma joga.la.
          </span>
        </p>
        <p dir="ltr">
          <span>
            JOGA LA COMUNICA&Ccedil;&Otilde;ES EM INTERNET LTDA ME ficar&aacute;
            isenta caso o cliente esteja vinculando an&uacute;ncios em qualquer
            meio de divulga&ccedil;&atilde;o ou redes sociais e tenha algum tipo
            de perda financeira caso o site{' '}
          </span>
          <a href="https://linktree.com.br/">
            <span>joga.la</span>
          </a>
          <span>
            {' '}
            tenha de sofrer qualquer altera&ccedil;&atilde;o ou qualquer tipo de
            atualiza&ccedil;&atilde;o no sistema, ou at&eacute; mesmo saia de
            funcionamento.
          </span>
        </p>
        <p dir="ltr">
          <span>QUANTIDADE DE LINKS GR&Aacute;TIS</span>
        </p>
        <p dir="ltr">
          <span>
            Podemos alterar a qualquer momento e sem aviso pr&eacute;vio a
            quantidade de links fornecidos gratuitamente.
          </span>
        </p>
        <p dir="ltr">
          <span>FORMAS DE PAGAMENTO</span>
        </p>
        <p dir="ltr">
          <span>
            Os pagamentos poder&atilde;o ser feitos por cart&atilde;o de
            cr&eacute;dito ou pelo Paypal ou boleto banc&aacute;rio, as formas
            de pagamento poder&atilde;o ser alteradas a qualquer momento e sem
            aviso pr&eacute;vio.
          </span>
        </p>
        <p dir="ltr">
          <span>AN&Uacute;NCIOS</span>
        </p>
        <p dir="ltr">
          <span>
            A JOGA LA COMUNICA&Ccedil;&Otilde;ES EM INTERNET LTDA ME
            poder&aacute; inserir an&uacute;ncios nas p&aacute;ginas criadas em
            nossa plataforma. Caso o cliente/usu&aacute;rio n&atilde;o esteja de
            acordo n&atilde;o poder&aacute; usar a nossa plataforma.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Os an&uacute;ncios poder&atilde;o ser inseridos automaticamente pelo
            Google utilizando a sua plataforma do Adsense ou podemos utilizar
            outros meios como o Tabula.
          </span>
        </p>
        <p dir="ltr">
          <span>
            A Ag&ecirc;ncia D Mais Sistemas Eireli-ME n&atilde;o tem controle
            sobre os tipos de an&uacute;ncios publicados nas p&aacute;ginas do
            site{' '}
          </span>
          <a href="https://linktree.com.br/">
            <span>https://joga.la</span>
          </a>
          <span>
            {' '}
            e tamb&eacute;m n&atilde;o pagar&aacute; ou dar&aacute; qualquer
            compensa&ccedil;&atilde;o financeira pela exibi&ccedil;&atilde;o de
            an&uacute;ncios nas p&aacute;ginas criadas gratuitamente em nossa
            plataforma.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Caso n&atilde;o esteja de acordo o cliente/usu&aacute;rio n&atilde;o
            poder&aacute; usar o nosso site{' '}
          </span>
          <a href="https://linktree.com.br/">
            <span>https://joga.la</span>
          </a>
        </p>
        <p dir="ltr">
          <span>DESCONTOS</span>
        </p>
        <p dir="ltr">
          <span>
            A JOGA LA COMUNICA&Ccedil;&Otilde;ES EM INTERNET LTDA ME
            poder&aacute; alterar a qualquer momento os descontos oferecidos nos
            seus planos, os clientes/usu&aacute;rios n&atilde;o poder&atilde;o
            alterar seu plano contratado, e tamb&eacute;m n&atilde;o
            poder&atilde;o solicitar uma parte da devolu&ccedil;&atilde;o do
            pagamento caso tenha contratado um plano com valor maior. Cada
            promo&ccedil;&atilde;o pode ter varia&ccedil;&otilde;es de
            pre&ccedil;o de acordo com a nossa pol&iacute;tica comercial.
          </span>
        </p>
        <p dir="ltr">
          <span>VALORES DAS MENSALIDADES</span>
        </p>
        <p dir="ltr">
          <span>
            Os valores poder&atilde;o sofrer altera&ccedil;&otilde;es cambiais
            de acordo com a varia&ccedil;&atilde;o do d&oacute;lar.
          </span>
        </p>
        <p dir="ltr">
          <span>
            RENOVA&Ccedil;&Atilde;O DE MENSALIDADE OU DE PLANOS DE
            SERVI&Ccedil;OS
          </span>
        </p>
        <p dir="ltr">
          <span>
            A mensalidade ser&aacute; renovada automaticamente e de forma
            recorrente at&eacute; que o cliente/usu&aacute;rio solicite o
            cancelamento dos nossos servi&ccedil;os.
          </span>
        </p>
        <p dir="ltr">
          <span>CANCELAMENTO E ESTORNO DE PAGAMENTO.</span>
        </p>
        <p dir="ltr">
          <span>
            O prazo para cancelamento e estorno de pagamentos &eacute; de 3 a 7
            dias &uacute;teis, pois dependem das operadoras de cart&atilde;o de
            cr&eacute;dito.
          </span>
        </p>
        <p dir="ltr">
          <span>GARANTIA</span>
        </p>
        <p dir="ltr">
          <span>
            O prazo para solicitar o cancelamento, devolu&ccedil;&atilde;o do
            dinheiro ou estorno dos valores pelo cart&atilde;o de cr&eacute;dito
            e d&eacute;bito junto a JOGA LA COMUNICA&Ccedil;&Otilde;ES EM
            INTERNET LTDA ME &eacute; de 7 dias &agrave; partir da data de
            compra dos nossos servi&ccedil;os.
          </span>
        </p>
        <p dir="ltr">
          <span>ALTERA&Ccedil;&Otilde;ES NA TAXA</span>
        </p>
        <p dir="ltr">
          <span>
            A JOGA LA COMUNICA&Ccedil;&Otilde;ES EM INTERNET LTDA ME, fazendo
            uso de seu crit&eacute;rio de forma exclusiva e a qualquer momento,
            poder&aacute; modificar os valores dos seus respectivos planos.
            Qualquer altera&ccedil;&atilde;o de planos ou servi&ccedil;os
            entrar&aacute; em vigor no fim do ciclo de faturamento atual.
          </span>
        </p>
        <p dir="ltr">
          <span>
            A JOGA LA COMUNICA&Ccedil;&Otilde;ES EM INTERNET LTDA ME
            manter&aacute; atualizado em seu site os valores dos planos e
            servi&ccedil;os. Cabe ao usu&aacute;rio atualizar a p&aacute;gina do
            nosso site apertando a tecla F5.
          </span>
        </p>
        <p dir="ltr">
          <span>PER&Iacute;ODO DE TESTE GR&Aacute;TIS.</span>
        </p>
        <p dir="ltr">
          <span>
            O per&iacute;odo de teste gr&aacute;tis pode variar de acordo com a
            nossa pol&iacute;tica de marketing interna e poder&aacute; ser
            finalizada e alterada a qualquer momento e sem aviso pr&eacute;vio.
          </span>
        </p>
        <p dir="ltr">
          <span>
            A quantidade de links gr&aacute;tis poder&atilde;o variar de acordo
            com a nossa pol&iacute;tica comercial.
          </span>
        </p>
        <p dir="ltr">
          <span>TERMINA&Ccedil;&Atilde;O</span>
        </p>
        <p dir="ltr">
          <span>
            A JOGA LA COMUNICA&Ccedil;&Otilde;ES EM INTERNET LTDA ME
            poder&aacute; rescindir ou suspender a conta do
            cliente/usu&aacute;rio de forma imediata, sem qualquer aviso
            pr&eacute;vio, caso haja viola&ccedil;&atilde;o dos termos da
            plataforma.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Ap&oacute;s a rescis&atilde;o, o cliente/usu&aacute;rio perde o
            direito de usar a nossa plataforma.
          </span>
        </p>
        <p dir="ltr">
          <span>
            ALTERA&Ccedil;&Atilde;O E ATUALIZA&Ccedil;&Otilde;ES NO SITE
          </span>
        </p>
        <p dir="ltr">
          <span>
            Podemos realizar altera&ccedil;&otilde;es e
            atualiza&ccedil;&otilde;es periodicamente e sem aviso pr&eacute;vio
            em nosso site https://joga.la e a JOGA LA COMUNICA&Ccedil;&Otilde;ES
            EM INTERNET LTDA ME ficar&aacute; isenta de qualquer
            responsabilidade em rela&ccedil;&atilde;o a bugs, recadastramento de
            conta pelo usu&aacute;rio, desaparecimento de links e bot&otilde;es
            dos usu&aacute;rios. A JOGA LA COMUNICA&Ccedil;&Otilde;ES EM
            INTERNET LTDA ME poder&aacute; alterar o layout e recursos do site{' '}
          </span>
          <a href="https://linktree.com.br/">
            <span>https://joga.la</span>
          </a>
          <span>
            {' '}
            sem aviso pr&eacute;vio e ficar&aacute; isenta de qualquer
            responsabilidade e indeniza&ccedil;&atilde;o ou
            compensa&ccedil;&atilde;o em rela&ccedil;&atilde;o a danos causados
            pelas altera&ccedil;&otilde;es.
          </span>
        </p>
        <p dir="ltr">
          <span>
            A JOGA LA COMUNICA&Ccedil;&Otilde;ES EM INTERNET LTDA ME ao fazer
            suas altera&ccedil;&otilde;es e atualiza&ccedil;&otilde;es fica
            isenta de qualquer responsabilidade em rela&ccedil;&atilde;o a
            qualquer danos causado a falta de tr&aacute;fego em sites, redes
            sociais, whatsapp, etc&hellip;
          </span>
        </p>
        <p dir="ltr">
          <span>
            A Ag&ecirc;ncia D Mais Sistemas Eireli &ndash; ME ficar&aacute;
            isenta de qualquer responsabilidade caso haja qualquer
            altera&ccedil;&atilde;o no site e o cliente tenha de cadastrar suas
            informa&ccedil;&otilde;es pessoais, dos links, bot&otilde;es e
            conte&uacute;do novamente.
          </span>
        </p>
        <p dir="ltr">
          <span>ENVIO DE EMAIL</span>
        </p>
        <p dir="ltr">
          <span>
            Com a finalidade de melhorarmos a nossa comunica&ccedil;&atilde;o,
            recebemos notifica&ccedil;&otilde;es quando nossos emails s&atilde;o
            abertos, se esta funcionalidade estiver dispon&iacute;vel no
            computador do cliente.
          </span>
        </p>
        <p dir="ltr">
          <span>
            PRAZO DE VALIDADE DE CUPONS DE DESCONTO E PROMO&Ccedil;&Otilde;ES
          </span>
        </p>
        <p dir="ltr">
          <span>
            1- Os cupons de desconto oferecidos pelo joga.la, n&atilde;o
            s&atilde;o acumulativos e s&oacute; podem ser usados uma vez por
            usu&aacute;rios e tem uma data de validade espec&iacute;fica para
            serem utilizados.
          </span>
        </p>
        <p dir="ltr">
          <span>
            2- O cliente dever&aacute; estar logado em sua conta para poder
            aplicar o cupom de desconto.
          </span>
        </p>
        <p dir="ltr">
          <span>
            3- Os cupons de desconto fornecidos pelo joga.la e seus parceiros
            poder&atilde;o ser utilizados apenas 1 (uma) vez pelo cliente, ou
            seja, n&atilde;o s&atilde;o acumulativos.
          </span>
        </p>
        <p dir="ltr">
          <span>
            4- Os descontos oferecidos nos produtos ou servi&ccedil;os prestados
            pelo joga.la poder&atilde;o ser alterados ou cancelados a qualquer
            momento sem nenhum aviso pr&eacute;vio e tem o seu per&iacute;odo de
            uso espec&iacute;fico.
          </span>
        </p>
        <p dir="ltr">
          <span>
            UTILIZA&Ccedil;&Atilde;O DE REMARKETING DO GOOGLE E FACEBOOK
          </span>
        </p>
        <p dir="ltr">
          <span>
            Podemos utilizar recursos de remarketing do Google ADS e do Facebook
            ADS para identificar quem visitou nosso site e assim exibir nossos
            an&uacute;ncios em diferentes websites e plataformas de
            conte&uacute;do.
          </span>
        </p>
        <p dir="ltr">
          <span>EXIBI&Ccedil;&Atilde;O DE AN&Uacute;NCIOS</span>
        </p>
        <p dir="ltr">
          <span>
            Poderemos exibir an&uacute;ncios de terceiros nas p&aacute;ginas e
            links criadas por nossos clientes.
          </span>
        </p>
        <p dir="ltr">
          <span>PROMO&Ccedil;&Otilde;ES</span>
        </p>
        <p dir="ltr">
          <span>
            Disponibilizamos v&aacute;rios tipos de promo&ccedil;&otilde;es em
            sites, blogs, redes sociais, etc.., cada promo&ccedil;&atilde;o pode
            ter valores e condi&ccedil;&otilde;es diferentes dependendo de cada
            negocia&ccedil;&atilde;o. O cliente/usu&aacute;rio n&atilde;o
            poder&aacute; pedir devolu&ccedil;&atilde;o parcial ou total do
            valor pago comparando com promo&ccedil;&otilde;es que estejam em
            vigor.
          </span>
        </p>
        <p dir="ltr">
          <span>SISTEMA DE AFILIADOS E CUPONS</span>
        </p>
        <p dir="ltr">
          <span>
            A JOGA LA COMUNICA&Ccedil;&Otilde;ES EM INTERNET LTDA ME reserva-se
            o direito de alterar a forma de comiss&atilde;o e a porcentagem a
            ser paga no sistema de afiliados ou de parceiros.
          </span>
        </p>
        <p dir="ltr">
          <span>
            ISEN&Ccedil;&Atilde;O E LIMITA&Ccedil;&Atilde;O DE RESPONSABILIDADE
          </span>
        </p>
        <p dir="ltr">
          <span>
            1 &ndash; Embora tenhamos como objetivo fornecer
            informa&ccedil;&otilde;es precisas sobre nossos produtos e
            servi&ccedil;os, elas s&atilde;o fornecidas por terceiros e
            n&atilde;o foram verificadas por n&oacute;s, por isso ficamos
            isentos de qualquer responsabilidade em rela&ccedil;&atilde;o
            &agrave;s informa&ccedil;&otilde;es dos produtos e servi&ccedil;os
            contidas neste site.
          </span>
        </p>
        <p dir="ltr">
          <span>
            2 &ndash; A forma de obter os resultados esperados pelos nossos
            produtos ou servi&ccedil;os podem variar de pessoa para pessoa e
            depende de v&aacute;rios fatores como: dedica&ccedil;&atilde;o,
            estudo, escolaridade e conhecimento. Apenas disponibilizamos uma
            plataforma que deve ser configurada pelo usu&aacute;rio para obter o
            melhor desempenho. Ficamos isentos de qualquer responsabilidade caso
            a conta de nossos clientes/usu&aacute;rios sejam banidas ou
            bloqueadas. Em caso de d&uacute;vidas em utilizar todos os recursos
            dispon&iacute;veis em nossa plataforma, disponibilizamos uma central
            de atendimento ao cliente atrav&eacute;s do email:{' '}
          </span>
          <span>contato@joga.la</span>
          <span>.</span>
        </p>
        <p dir="ltr">
          <span>3 &ndash; Voc&ecirc; concorda e entende que o site </span>
          <a href="https://linktree.com.br/">
            <span>https://joga.la</span>
          </a>
          <span>
            {' '}
            n&atilde;o &eacute; respons&aacute;vel por seu resultado esperado. O
            cliente/usu&aacute;rio dever&aacute; configurar corretamente seu
            link da forma correta. Logo, voc&ecirc; assume a responsabilidade de
            que poder&aacute; n&atilde;o obter os resultados esperados caso
            n&atilde;o configure corretamente.
          </span>
        </p>
        <p dir="ltr">
          <span>
            4 &ndash; O cliente concorda que o Facebook (Facebook, Instagram e o
            Whatsapp) tem suas pr&oacute;prias normas e termos de uso que podem
            ser diferentes das nossas normas e termos. Por isso dever&atilde;o
            ser lidas e aceitas sob sua responsabilidade.
          </span>
        </p>
        <p dir="ltr">
          <span>
            5 &ndash; A JOGA LA COMUNICA&Ccedil;&Otilde;ES EM INTERNET LTDA ME
            fica isento caso a conta de cliente/usu&aacute;rio seja bloqueada ou
            suspensa caso haja viola&ccedil;&atilde;o de regras do Instagram.
            Cabe ao cliente/usu&aacute;rio configurar de maneira adequada para
            que possa obter os resultados esperados.
          </span>
        </p>
        <p dir="ltr">
          <span>
            6 &ndash; A JOGA LA COMUNICA&Ccedil;&Otilde;ES EM INTERNET LTDA ME
            poder&aacute; encerrar suas atividades a qualquer momento e sem
            aviso pr&eacute;vio.
          </span>
        </p>
        <p dir="ltr">
          <span>
            7 &ndash; A JOGA LA COMUNICA&Ccedil;&Otilde;ES EM INTERNET LTDA ME
            se isenta de qualquer responsabilidade, caso ocorra alguma
            mudan&ccedil;a no API do Instagram ou Facebook e isso ocasione
            instabilidade e interrup&ccedil;&otilde;es no sistema.
          </span>
        </p>
        <p dir="ltr">
          <span>
            8 &ndash; A JOGA LA COMUNICA&Ccedil;&Otilde;ES EM INTERNET LTDA ME
            ficar&aacute; isento de qualquer responsabilidade caso haja
            interrup&ccedil;&atilde;o dos servi&ccedil;os, ou caso haja qualquer
            problema de funcionamento dos servidores onde est&aacute; locado os
            nossos servi&ccedil;os.
          </span>
        </p>
        <p dir="ltr">
          <span>
            9- Poderemos remover ou cancelar os servi&ccedil;os oferecidos pelo
            site joga.la de qualquer conta ou usu&aacute;rio sem aviso
            pr&eacute;vio e o usu&aacute;rio n&atilde;o ter&aacute; direito a
            qualquer compensa&ccedil;&atilde;o ou indeniza&ccedil;&atilde;o.
          </span>
        </p>
        <p dir="ltr">
          <span>
            9.1- Poderemos enviar para o usu&aacute;rio dos nossos
            servi&ccedil;os um email notificando o aviso de cancelamento dos
            servi&ccedil;os.
          </span>
        </p>
        <p dir="ltr">
          <span>
            10- N&atilde;o somos respons&aacute;veis por compartilhamento de
            links maliciosos fornecidos por qualquer usu&aacute;rio do nosso
            servi&ccedil;o.
          </span>
        </p>
        <p dir="ltr">
          <span>
            OBS&gt; &Eacute; de responsabilidade do usu&aacute;rio manter um
            email v&aacute;lido.
          </span>
        </p>
        <p dir="ltr">
          <span>
            A JOGA LA COMUNICA&Ccedil;&Otilde;ES EM INTERNET LTDA ME permite que
            voc&ecirc; publique, vincule, armazene, compartilhe e disponibilize
            determinadas informa&ccedil;&otilde;es como textos, v&iacute;deos,
            gr&aacute;ficos e outros materiais. Logo, o cliente/usu&aacute;rio
            &eacute; respons&aacute;vel pelo conte&uacute;do que for publicar ou
            compartilhar em nossa plataforma, incluindo sua legalidade,
            confiabilidade e adequa&ccedil;&atilde;o.
          </span>
        </p>
        <p dir="ltr">
          <span>O cliente/usu&aacute;rio declara e garante que:</span>
        </p>
        <p dir="ltr">
          <span>1- O conte&uacute;do &eacute; de sua autoria.</span>
        </p>
        <p dir="ltr">
          <span>
            2- A publica&ccedil;&atilde;o do conte&uacute;do por parte do
            cliente/usu&aacute;rio n&atilde;o viola os direitos de privacidade,
            publicidade, autoria, contratuais ou quaisquer outros direitos de
            qualquer pessoa.
          </span>
        </p>
        <p dir="ltr">
          <span>
            3- N&atilde;o far&aacute; com que haja viola&ccedil;&atilde;o de
            qualquer lei, regulamento, c&oacute;digo ou obriga&ccedil;&atilde;o
            legal.
          </span>
        </p>
        <p dir="ltr">
          <span>
            4- N&atilde;o ir&aacute; postar qualquer conte&uacute;do que seja
            considerado inadequado, obsceno, difamat&oacute;rio, depreciativo,
            indecente, ofensivo, pornogr&aacute;fico ou que contenha qualquer
            forma de discrimina&ccedil;&atilde;o.
          </span>
        </p>
        <p dir="ltr">
          <span>
            5- N&atilde;o ser&atilde;o postados quaisquer conte&uacute;dos que
            incluam v&iacute;rus de software, arquivos maliciosos,
            c&oacute;digos de computador ou quaisquer outros programas
            projetados para interromper, destruir ou limitar o funcionamento da
            plataforma{' '}
          </span>
          <a href="https://linktree.com.br/">
            <span>joga.la</span>
          </a>
          <span> e seus demais clientes/usu&aacute;rios.</span>
        </p>
        <p dir="ltr">
          <span>
            N&atilde;o temos obriga&ccedil;&atilde;o de monitorar regularmente a
            precis&atilde;o ou a confiabilidade do seu conte&uacute;do
            adicionado &agrave; nossa plataforma. N&oacute;s apenas nos
            reservamos o direito de modificar ou remover qualquer
            conte&uacute;do que esteja fora dos termos de uso do Instagram e da
            pol&iacute;tica do joga.la a qualquer momento.
          </span>
        </p>
        <p dir="ltr">
          <span>
            O cliente/usu&aacute;rio concorda em manter todos os dados
            necess&aacute;rios para que seu conte&uacute;do n&atilde;o descumpra
            nenhuma das exig&ecirc;ncias do joga.la e torna tais registros
            dispon&iacute;veis mediante nosso pedido razo&aacute;vel.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Ao aceitar estes termos de uso, o cliente/usu&aacute;rio concorda
            que todo conte&uacute;do fornecido em nossa plataforma estar&aacute;
            dispon&iacute;vel publicamente e o mesmo assume os riscos envolvidos
            com tais divulga&ccedil;&otilde;es p&uacute;blicas.
          </span>
        </p>
        <p dir="ltr">
          <span>USO DE COMENT&Aacute;RIOS</span>
        </p>
        <p dir="ltr">
          <span>
            Os depoimentos e coment&aacute;rios realizados em nosso site ou em
            nossas redes sociais poder&atilde;o ser utilizados para
            divulga&ccedil;&atilde;o de nossos produtos e servi&ccedil;os.
          </span>
        </p>
        <p dir="ltr">
          <span>
            USO DE P&Aacute;GINAS DE CLIENTES EM PUBLICIDADE NO SITE joga.la
          </span>
        </p>
        <p dir="ltr">
          <span>As p&aacute;ginas criadas em nosso site </span>
          <a href="https://linktree.com.br/">
            <span>https://joga.la</span>
          </a>
          <span>
            {' '}
            poder&atilde;o ser usadas como exemplo em nosso site e
            servir&atilde;o apenas para exemplificar para nossos usu&aacute;rios
            e clientes as possibilidades de usabilidade. Caso n&atilde;o esteja
            de acordo envie um email para: contato@joga.la
          </span>
        </p>
        <p dir="ltr">
          <span>UTILIZA&Ccedil;&Atilde;O INCORRETA DO SISTEMA</span>
        </p>
        <p dir="ltr">
          <span>
            A JOGA LA COMUNICA&Ccedil;&Otilde;ES EM INTERNET LTDA ME
            poder&aacute; cancelar a qualquer momento e sem aviso pr&eacute;vio
            contas de clientes/usu&aacute;rios que estiverem usando nosso
            sistema de forma abusiva e que possa prejudicar o funcionamento da
            nossa plataforma.
          </span>
        </p>
        <p dir="ltr">
          <span>
            O uso e configura&ccedil;&atilde;o da sua conta ou perfil &eacute;
            de responsabilidade do cliente/usu&aacute;rio.
          </span>
        </p>
        <p dir="ltr">
          <span>REGRAS DE AFILIA&Ccedil;&Atilde;O</span>
        </p>
        <p dir="ltr">
          <span>
            1- Fica proibido publicar imagens de pessoas sem roupa ou imagens
            desagrad&aacute;veis.
          </span>
        </p>
        <p dir="ltr">
          <span>
            2- &Eacute; proibido enviar email, SMS, mensagens com a assinatura
            do joga.la.
          </span>
        </p>
        <p dir="ltr">
          <span>
            3- Fica proibido a reprodu&ccedil;&atilde;o parcial ou total dos
            nossos conte&uacute;dos em v&iacute;deo no Youtube, blog e redes
            sociais.
          </span>
        </p>
        <p dir="ltr">
          <span>
            4- Fica proibido fazer an&uacute;ncios, banners e
            divulga&ccedil;&atilde;o usando nossa logomarcas sem aviso
            pr&eacute;vio.
          </span>
        </p>
        <p dir="ltr">
          <span>
            5- Os coment&aacute;rios feitos por usu&aacute;rios do joga.la
            poder&atilde;o ser utilizados para fins informativos em nossas redes
            sociais e sites.
          </span>
        </p>
        <p dir="ltr">
          <span>
            6- &Eacute; proibido alterar os valores das promo&ccedil;&otilde;es
            dos planos oferecidos pelo joga.la.
          </span>
        </p>
        <p dir="ltr">
          <span>
            7- Fica proibido oferecer b&ocirc;nus vinculado ao nome joga.la Os
            b&ocirc;nus oferecidos pelos afiliados dever&atilde;o estar bem
            definido que &eacute; um b&ocirc;nus oferecido pelo afiliado.
          </span>
        </p>
        <p dir="ltr">
          <span>
            8- &Eacute; proibido revender a plataforma joga.la em dinheiro.
          </span>
        </p>
        <p dir="ltr">
          <span>9- Formas de pagamento ao afiliado.</span>
        </p>
        <p dir="ltr">
          <span>
            O pagamento das comiss&otilde;es de afiliados ser&atilde;o efetuados
            30 dias ap&oacute;s &agrave; confirma&ccedil;&atilde;o do pagamento
            pelos clientes.
          </span>
        </p>
        <p dir="ltr">
          <span>10- Imposto a ser pago pelo afiliado.</span>
        </p>
        <p dir="ltr">
          <span>
            O afiliado fica ciente que &eacute; sua obriga&ccedil;&atilde;o o
            pagamento dos impostos devido as vendas do nosso servi&ccedil;o.
          </span>
        </p>
        <p dir="ltr">
          <span>
            11- Prazo para solicita&ccedil;&atilde;o de pagamento das
            comiss&otilde;es &eacute; de 12 meses, ap&oacute;s este
            per&iacute;odo o valor que estiverem na conta do afiliado
            ser&aacute; zerada e implicar&aacute; em perda dos valores de
            comiss&atilde;o.
          </span>
        </p>
        <p dir="ltr">
          <span>
            12- A JOGA LA COMUNICA&Ccedil;&Otilde;ES EM INTERNET LTDA ME
            (joga.la) somente remunerar&aacute; o afiliado que cumprir todas as
            condi&ccedil;&otilde;es estabelecidas neste termo.
          </span>
        </p>
        <p dir="ltr">
          <span>
            13- A JOGA LA COMUNICA&Ccedil;&Otilde;ES EM INTERNET LTDA ME
            poder&aacute; a qualquer momento excluir do seu corpo de afiliado
            qualquer pessoa pessoa , e n&atilde;o precisar&aacute; dar
            explica&ccedil;&atilde;o ou motivo pela exclus&atilde;o do sistema
            de afiliados.
          </span>
        </p>
        <p dir="ltr">
          <span>AVISOS LEGAIS</span>
        </p>
        <p dir="ltr">
          <span>
            Ao utilizar o nosso link no Instagram ou Facebook, o
            cliente/usu&aacute;rio assume a responsabilidade caso haja bloqueio
            da sua conta.
          </span>
        </p>
        <p dir="ltr">
          <span>
            As atualiza&ccedil;&otilde;es da nossa plataforma ser&atilde;o
            realizadas para garantir a seguran&ccedil;a e o bom funcionamento do
            sistema. O joga.la fica isento por quaisquer omiss&otilde;es,
            imprecis&otilde;es t&eacute;cnicas ou erros tipogr&aacute;ficos que
            impliquem em desempenhos de resultados.
          </span>
        </p>
        <p dir="ltr">
          <span>MARCAS REGISTRADAS</span>
        </p>
        <p dir="ltr">
          <span>
            As marcas, logos e v&iacute;deos dos servi&ccedil;os deste site
            s&atilde;o de propriedade de seus respectivos donos que det&eacute;m
            todos os seus direitos e servem apenas para ilustrar, ensinar e
            identificar os servi&ccedil;os dos seus respectivos autores.
          </span>
        </p>
        <p dir="ltr">
          <span>HIPERLINKS</span>
        </p>
        <p dir="ltr">
          <span>
            Nossa plataforma possui links para outros websites que n&atilde;o
            possuem nenhum v&iacute;nculo com a nossa empresa. Portanto,
            n&atilde;o podemos nos responsabilizar pelo conte&uacute;do dos
            links desses sites. O usu&aacute;rio assume o risco de navegar por
            esses links e hiperlinks.
          </span>
        </p>
        <p dir="ltr">
          <span>
            RESTRI&Ccedil;&Otilde;ES NA UTILIZA&Ccedil;&Atilde;O DOS NOSSOS
            SERVI&Ccedil;OS
          </span>
        </p>
        <p dir="ltr">
          <span>
            O usu&aacute;rio n&atilde;o poder&aacute; divulgar
            informa&ccedil;&otilde;es ofensivas ou caluniosas contra empresa ou
            pessoas.
          </span>
        </p>
        <p dir="ltr">
          <span>
            O usu&aacute;rio n&atilde;o poder&aacute; divulgar imagens e
            conte&uacute;dos sexuais ou pornogr&aacute;ficos.
          </span>
        </p>
        <p dir="ltr">
          <span>LINKS PARA OUTROS SITES</span>
        </p>
        <p dir="ltr">
          <span>
            A plataforma pode conter links para sites ou servi&ccedil;os de
            terceiros que n&atilde;o s&atilde;o de propriedade ou controlados
            pelo joga.la.
          </span>
        </p>
        <p dir="ltr">
          <span>
            A A JOGA LA COMUNICA&Ccedil;&Otilde;ES EM INTERNET LTDA ME
            n&atilde;o tem controle e n&atilde;o assume a responsabilidade pelo
            conte&uacute;do, pol&iacute;ticas de privacidade ou pr&aacute;ticas
            de quaisquer sites ou servi&ccedil;os de terceiros.
          </span>
        </p>
        <p dir="ltr">
          <span>
            PUBLICIDADE E UTILIZA&Ccedil;&Atilde;O DAS P&Aacute;GINAS E LINKS
            CRIADOS PELO SITE joga.la
          </span>
        </p>
        <p dir="ltr">
          <span>
            Poderemos usar as imagens das p&aacute;ginas criadas pelo nosso site
            a fins de ilustrar o funcionamento e a forma do tipo de
            conte&uacute;do que podem ser criados e compartilhado pelos nossos
            usu&aacute;rios. Ficamos isentos de qualquer pagamento ou
            compensa&ccedil;&atilde;o financeira pelo uso ou
            vincula&ccedil;&atilde;o das imagens das p&aacute;ginas dos nossos
            usu&aacute;rios/clientes.
          </span>
        </p>
        <p dir="ltr">
          <span>
            OBS. Caso n&atilde;o queira que o conte&uacute;do seja publicado em
            nosso site o usu&aacute;rio n&atilde;o poder&aacute; usar o nosso
            sistema.
          </span>
        </p>
        <p dir="ltr">
          <span>LINKS PARA OUTROS SITES</span>
        </p>
        <p dir="ltr">
          <span>
            O nosso servi&ccedil;o de distribui&ccedil;&atilde;o de links
            poder&aacute; conter links e propaganda de terceiros que n&atilde;o
            s&atilde;o controlados pela Ag&ecirc;ncia D Mais Sistemas Eirelli
            ME.
          </span>
        </p>
        <p dir="ltr">
          <span>
            An&uacute;ncios de terceiros, incluindo o Google, Tabola, usam
            cookies para veicular an&uacute;ncios com base em visitas anteriores
            do usu&aacute;rio ao seu website ou a outros websites.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Com o uso de cookies de publicidade, o Google e os parceiros dele
            podem veicular an&uacute;ncios para os usu&aacute;rios com base nas
            visitas feitas no site joga.la.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Os usu&aacute;rios do site joga.la podem desativar a publicidade
            personalizada acessando as Configura&ccedil;&otilde;es de
            an&uacute;ncios. Caso tenha alguma d&uacute;vida como desativar os
            an&uacute;ncios acesse o site www.aboutads.info para desativar o uso
            de cookies de publicidade personalizada de terceiros.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Se voc&ecirc; n&atilde;o tiver desativado a veicula&ccedil;&atilde;o
            de an&uacute;ncios de terceiros, os cookies de outros fornecedores
            ou redes de terceiros tamb&eacute;m poder&atilde;o ser usados para
            veicular an&uacute;ncios na sua p&aacute;gina fornecida pelo
            joga.la.
          </span>
        </p>
        <p dir="ltr">
          <span>
            O site joga.la veicula an&uacute;ncios do Google atrav&eacute;s do
            Adsense ou de terceiros ou outros fornecedores de an&uacute;ncios,
            caso n&atilde;o concorde voc&ecirc; n&atilde;o poder&aacute; usar o
            nosso servi&ccedil;o e dever&aacute; excluir a sua conta.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Os usu&aacute;rios ou clientes dos servi&ccedil;os do site{' '}
          </span>
          <a href="https://linktree.com.br/">
            <span>https://joga.la</span>
          </a>
          <span>
            {' '}
            n&atilde;o ter&atilde;o direito a nenhuma compensa&ccedil;&atilde;o
            financeiro ou qualquer tipo de recebimento ou
            remunera&ccedil;&atilde;o pela veicula&ccedil;&atilde;o de
            an&uacute;ncios ou propaganda nas p&aacute;ginas criadas pelo nosso
            sistema.
          </span>
        </p>
        <p dir="ltr">
          <span>USO TESTE VERS&Atilde;O TRIAL (14 DIAS GR&Aacute;TIS)</span>
        </p>
        <p dir="ltr">
          <span>
            &Eacute; disponibilizado uma vers&atilde;o de teste que tem por
            dura&ccedil;&atilde;o 14 dias gr&aacute;tis da nova plataforma do
            site hhttps://joga.la&nbsp;
          </span>
        </p>
        <p dir="ltr">
          <span>
            ap&oacute;s este per&iacute;odo de utiliza&ccedil;&atilde;o o
            cliente/usu&aacute;rio dever&aacute; escolher um plano para
            continuar acessando a sua conta.
          </span>
        </p>
        <p dir="ltr">
          <span>
            A mudan&ccedil;a do tempo do per&iacute;odo de teste poder&aacute;
            ser alterada sem aviso pr&eacute;vio.&nbsp;
          </span>
        </p>
        <p dir="ltr">
          <span>USO DE COOKIES</span>
        </p>
        <p dir="ltr">
          <span>
            Cookies s&atilde;o identifica&ccedil;&otilde;es da
            intera&ccedil;&atilde;o com nosso site ou nossas publicidades, que
            s&atilde;o transferidas para o equipamento do cliente (computador,
            tablet ou smartphone) visando reconhec&ecirc;-lo na pr&oacute;xima
            navega&ccedil;&atilde;o.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Utilizamos cookies para proporcionar uma melhor experi&ecirc;ncia em
            nosso site e viabilizar recursos personalizados, como
            recomenda&ccedil;&otilde;es de produtos, publicidades e
            informa&ccedil;&otilde;es adicionais de itens de interesse do
            cliente.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Os principais navegadores de internet possibilitam ao cliente
            gerenciar a utiliza&ccedil;&atilde;o dos cookies em sua
            m&aacute;quina.
          </span>
        </p>
        <p dir="ltr">
          <span>
            A nossa recomenda&ccedil;&atilde;o &eacute; que mantenha o
            salvamento de cookies ligados. Desta forma, &eacute; poss&iacute;vel
            utilizar todos os recursos de navega&ccedil;&atilde;o personalizada
            oferecidos pelo joga.la, mas, caso o cliente n&atilde;o concorde,
            &eacute; poss&iacute;vel desabilitar esta fun&ccedil;&atilde;o no
            navegador.
          </span>
        </p>
        <p dir="ltr">
          <span>
            Para remo&ccedil;&atilde;o dos Cookies ou Cache siga os
            procedimentos indicados pelos fabricantes* para cada navegador**:
          </span>
        </p>
        <p dir="ltr">
          <span>
            *Google Chrome:
            (https://support.google.com/chrome/answer/95647?hl=pt-BR)
          </span>
        </p>
        <p dir="ltr">
          <span>
            Internet
            Explorer:(http://windows.microsoft.com/pt-br/internet-explorer/delete-manage-cookies#ie=ie-11)
          </span>
        </p>
        <p dir="ltr">
          <span>
            Firefox:
            (https://support.mozilla.org/pt-BR/kb/ativando-e-desativando-cookies)
          </span>
        </p>
        <p dir="ltr">
          <span>
            Safari:
            (http://safari.helpmax.net/ps/privacidade-e-seguranca/como-remover-cookies/)
          </span>
        </p>
        <p dir="ltr">
          <span>
            Opera: (http://help.opera.com/Windows/10.20/pt/history.html)
          </span>
        </p>
        <p dir="ltr">
          <span>
            * Aten&ccedil;&atilde;o: As informa&ccedil;&otilde;es acima
            s&atilde;o fornecidas pelos sites oficiais, A JOGA LA
            COMUNICA&Ccedil;&Otilde;ES EM INTERNET LTDA ME (joga.la) n&atilde;o
            se responsabiliza por danos ou perdas de informa&ccedil;&otilde;es
            geradas pelo uso desses procedimentos.
          </span>
        </p>
        <p dir="ltr">
          <span>
            ** Essa opera&ccedil;&atilde;o pode ser necess&aacute;ria todas as
            vezes que o equipamento for substitu&iacute;do, reinstalado ou tiver
            suas configura&ccedil;&otilde;es alteradas para o padr&atilde;o
            original.
          </span>
        </p>
        <p dir="ltr">
          <span>JOGA LA COMUNICA&Ccedil;&Otilde;ES EM INTERNET LTDA ME.</span>
        </p>
        <p dir="ltr">
          <span>CNPJ: 45.015.696/0001-68</span>
        </p>
        <p>
          <span id="docs-internal-guid-4cb80f87-7fff-d270-6c73-9e5b11f5d802">
            <span>Contato: </span>
            <span>contato@joga.la</span>
          </span>
        </p>
      </Content>
      <Footer />
    </Container>
  );
};

export default Privacidade;
