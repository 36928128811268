import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const db = firebase.firestore();

const checkUserUrl = async (url: string) => {
  let commentsQuery = db.collection('user').where('userName', '==', url);

  return commentsQuery.get();
};

export default checkUserUrl;
