import React from "react";
import { AuthProvider, UserProvider } from "./Firebase/context";
import Routes from "./Routes";

function App() {
  return (
    <AuthProvider>
      <UserProvider>
        <Routes />
      </UserProvider>
    </AuthProvider>
  );
}

export default App;
