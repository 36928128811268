import React from 'react';

import { Container, Content, ImgQuemSomos, TextPre } from './style';
import imgQuemSomos from '../../assets/img/quem-somos.jpg';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

const Sobre: React.FC = () => {
  return (
    <Container>
      <Header />
      <Content>
        <h1>Sobre o Joga.la</h1>
        <TextPre>
          O Joga.la torna seu conteúdo online mais acessível, mais fácil de
          gerenciar e mais práticvo.
        </TextPre>
        <ImgQuemSomos src={imgQuemSomos} alt="Sobre o Joga.la" />
        <br />
        <br />
        <h4>
          Somos uma ferramenta para conectar seguidores a todo o seu mundo
          online.
        </h4>
        <p>
          Um joga.la não apenas direciona os seguidores na direção de sua
          escolha - para seus outros perfis sociais, loja de comércio eletrônico
          ou conteúdo que você deseja compartilhar - mas também ajuda a manter
          os seguidores em seu ecossistema online por mais tempo. Ele permite
          que os usuários compartilhem mais, vendam mais, façam mais curadoria e
          cresçam mais.
        </p>
      </Content>
      <Footer />
    </Container>
  );
};

export default Sobre;
