import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import { AuthContext } from '../Firebase/context';

import Home from '../pages/Home';
import PageProfile from '../pages/Profile';
import SignIn from '../pages/SignIn';
import Regsiter from '../pages/Register';
import Sobre from '../pages/Sobre';
import Funciona from '../pages/ComoFunciona';
import Privacidade from '../pages/Privacidade';
import Lgpd from '../pages/LGPD';
import CompleteProfile from '../pages/CompleteProfile';
import CropImageCape from '../pages/CropImageCape';

const AppRoutes: React.FC = () => {
  const { user } = useContext(AuthContext);

  return user ? (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/complete-profile" component={CompleteProfile} />
      <Route path="/crop-image-cape" component={CropImageCape} />
      <Route path="/sobre" component={Sobre} />
      <Route path="/como-funciona" component={Funciona} />
      <Route path="/privacidade" component={Privacidade} />
      <Route path="/lgpd" component={Lgpd} />
      <Route path="/:id" component={PageProfile} />
    </Switch>
  ) : (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/sobre" component={Sobre} />
      <Route path="/como-funciona" component={Funciona} />
      <Route path="/privacidade" component={Privacidade} />
      <Route path="/lgpd" component={Lgpd} />
      <Route path="/login" component={SignIn} />
      <Route path="/register" component={Regsiter} />
      <Route path="/:id" component={PageProfile} />
    </Switch>
  );
};

export default AppRoutes;
