import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
`;

export const ImgQuemSomos = styled.img`
  width: 100%;
  max-width: 600px;
`;

export const TextPre = styled.p`
  font-size: 24px !important;
  max-width: 600px;
  width: 100%;
  border-left: 4px solid #442c75;
  line-height: 30px !important;
  margin: 40px 0;
`;

export const Content = styled.div`
  flex: 1;
  width: 100%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: auto;
  height: calc(100% - 195px);
  border-radius: 6px;
  overflow-y: scroll;
  text-align: left;

  &::-webkit-scrollbar {
    width: 0;
    border-radius: 6px;
  }

  > h1 {
    padding: 0;
    font-size: 25px;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 50px;
    margin-top: 20px;
  }

  > p {
    padding: 0;
    line-height: 25px;
    text-align: justify;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    font-weight: 100;
  }
`;
