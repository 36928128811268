import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  overflow: none;
`;

export const Important = styled.span`
  font-size: 14px;
  color: #f8ce05;
`;

export const Content = styled.div`
  width: 100%;
  .box-left {
    display: inline-block;
    width: 50%;
    padding-left: 3%;
    box-sizing: border-box;
    padding-top: 7%;
    padding-bottom: 2%;

    @media screen and (max-width: 900px) {
      width: 100%;
      text-align: center;
    }

    @media screen and (max-device-width: 480px) {
      padding: 30px 2%;
    }

    > h1 {
      font-size: 56px;
      font-family: 'Circular Std';
      font-weight: bold;
      font-style: normal;
      color: #442c75;
      margin-bottom: 42px;
      line-height: 64px;

      @media screen and (max-width: 1366px) {
        font-size: 44px;
        line-height: 48px;
        margin-top: -69px;
      }

      @media screen and (max-device-width: 480px) {
        font-size: 28px;
        line-height: 30px;
        margin: 15px 0 20px 0;
      }

      > span {
        position: relative;
        z-index: 1;
        ::after {
          content: ' ';
          background-color: #f8ce05;
          width: 100%;
          height: 25px;
          position: absolute;
          left: 0;
          bottom: 1px;
          z-index: -1;

          @media screen and (max-device-width: 480px) {
            height: 16px;
            bottom: 0px;
          }
        }
      }
    }
    > p {
      font-size: 20px;
      font-family: 'Open Sans', sans-serif;
      font-weight: 300;
      color: #7c7c7c;

      @media screen and (max-width: 1366px) {
        font-size: 18px;
        line-height: 1.5;
      }

      @media screen and (max-device-width: 480px) {
        font-size: 16px;
        padding: 0 2%;
        line-height: 22px;
        margin-top: 35px;
      }
    }
  }
  .box-right {
    display: inline-block;
    width: 50%;
    vertical-align: top;
    text-align: left;

    > img {
      width: 100%;
      max-width: 842px;
      position: absolute;
      right: 0;

      @media screen and (max-width: 1366px) {
        max-width: 530px;
      }

      @media screen and (max-width: 900px) {
        display: none;
      }
    }
  }

  .btn-register {
    font-size: 28px;
    font-family: 'Circular Std';
    font-weight: bold;
    color: #fff;
    background-color: #442c75;
    border: none;
    padding: 15px 75px;
    border-radius: 7px;
    margin-top: 40px;
    animation-direction: alternate;
    -webkit-animation-name: pulse;
    animation-name: pulse;
    animation: pulse 1.5s infinite;
    cursor: pointer;

    @media screen and (max-width: 1366px) {
      font-size: 21px;
      padding: 10px 45px;
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
`;
