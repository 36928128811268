import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Container, Logo, Cadastro } from './style';
import LogoJogala from '../../../assets/img/Logo.svg';
import imgVoltar from '../../../assets/img/img-voltar.svg';

const HeaderNaoLogado: React.FC = () => {
  const url = useHistory().location.pathname;

  return (
    <Container className={url === '/' ? 'full-width' : ''}>
      {url !== '/' ? (
        <div className="voltar">
          <Link to="/"><img src={imgVoltar} /></Link>
          <p>
            <Link to="/">Voltar</Link>
          </p>
        </div>
      ) : (
        <Logo>
          <Link to={'/'}>
            <img src={LogoJogala} alt="Logo" />
          </Link>
        </Logo>
      )}

      <Cadastro>
            <Link to="/register" className="btn-register">
              Cadastre-se
            </Link>
            <Link to="/login" className="btn-entrar">
              Entrar
            </Link>
          </Cadastro>
    </Container>
  );
};

export default HeaderNaoLogado;
