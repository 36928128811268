import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  width: 100%;
  padding-top: 15px;
  font-family: 'Open Sans', sans-serif;

  > ul {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .link {
      list-style: none;
      padding: 10px;
      font-size: 14px;
      font-family: 'Open Sans', sans-serif;
      font-weight: 300;
      cursor: pointer;

      a {
        text-decoration: none;
        color: #8d8d8d;
      }

      @media screen and (max-device-width: 480px) {
        font-size: 12px;
      }
    }
  }
`;
