import React, { ChangeEvent, useContext } from 'react';
import { Button } from 'react-bootstrap';
import { AuthContext } from '../../Firebase/context';
import { Redirect, Link } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { toast } from 'react-toastify';
import { Container, Content } from './style';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useStore } from '../../hooks/SignIn';

interface User {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const { user } = useContext(AuthContext);

  const isLoading: any = useStore((state: any) => state.isLoading);
  const login: any = useStore((state: any) => state.login);
  const setLogin: any = useStore((state: any) => state.setLogin);
  const setLoading: any = useStore((state: any) => state.setLoading);

  const hendleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    let dataLogin = { ...login, [event.target.name]: event.target.value };
    setLogin(dataLogin);
  };

  const loginUser = (user: User) => {
    let completeProfile: boolean;
    console.log({user});

    if (isLoading) return ;
    setLoading(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(user.email, user.password)
      .then((userCredential) => {
        let profileComplete = userCredential.user?.uid;
        toast('🎉  Bem-vindo ao joga.la!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        firebase
          .firestore()
          .collection('user')
          .doc(profileComplete)
          .get()
          .then((profile) => {
            let profileComp = profile.data()?.completeProfile;
            completeProfile = profileComp;
          })
          .then(() => {
            window.location.href =
              completeProfile === true ? '/profile' : '/complete-profile';
          });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        const errorMessage = error.message;
        toast.error(`😬  Ops, ${errorMessage}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      });
  };

  const handleLogin = () => {
    !login?.email &&
      toast.warn('📣  Por favor preencha seu email', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });

    !login?.password &&
      toast.warn('📣  Por favor preencha sua senha', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });

    login?.email && login?.password && loginUser(login);
  };

  return (
    <Container>
      {!!user && <Redirect to={{ pathname: '/' }} />}
      <Header />
      <Content>
        <div className="div-top">
          <h1>Entre com sua conta</h1>
        </div>
        <div className="div-center">
          <div style={{ position: 'relative' }}>
            <TextField
              type="text"
              className="inputAnimado"
              id="standard-required"
              label="Email"
              variant="filled"
              onChange={hendleChange}
              name="email"
            />
          </div>
          <div>
            <TextField
              type="password"
              className="inputAnimado"
              id="outlined-multiline-flexible"
              label="Senha"
              variant="filled"
              onChange={hendleChange}
              name="password"
            />
          </div>
          <div className="sua-conta">
            <p>
              Precisa de ajuda?{' '}
              <Link to="/login">
                <span>Esqueci a senha</span>
              </Link>
            </p>
          </div>
          <div className="btn-entrar">
            <Button
              variant="primary"
              disabled={isLoading}
              onClick={() => {
                !isLoading ? handleLogin() : null;
              }}
            >
              {isLoading ? 'Entrando…' : 'Entrar'}
            </Button>
          </div>
          <div className="sua-conta">
            <p>
              Não possui conta?{' '}
              <Link to="/register">
                <span>Cadastre-se</span>
              </Link>
            </p>
          </div>
        </div>
      </Content>
      <Footer />
    </Container>
  );
};

export default SignIn;
