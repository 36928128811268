import React from 'react';
import Skeleton from 'react-loading-skeleton';

import styled from 'styled-components';
import Insta from '../../assets/img/icon/insta.svg';
import Face from '../../assets/img/icon/face.svg';
import Linkedin from '../../assets/img/icon/linkedin.svg';
import Twitter from '../../assets/img/icon/twitter.svg';
import Youtube from '../../assets/img/icon/youtube.svg';

import {
  Container,
  BoxLineContent,
  BoxContentBio,
  BoxContentLine,
  TextAlert,
  ButtonLink
} from './style';
import { AuthContext } from '../../Firebase/context';

import Header from '../../components/Header';
import ProfileHeader from '../../components/ProfileHeader';
const LoadingProfile: React.FC = () => {
  const ContentBio = styled(BoxContentBio)`
    -moz-transition: height 1s ease;
    -webkit-transition: height 1s ease;
    -o-transition: height 1s ease;
    transition: height 1s ease;
  `;

  const ButtonInsta = styled(ButtonLink)`
    background: rgb(202, 101, 184);
    background: linear-gradient(
      90deg,
      rgba(202, 101, 184, 1) 0%,
      rgba(209, 112, 122, 1) 100%
    );

    span {
      background: #d1707b;
    }
  `;

  const ButtonFace = styled(ButtonLink)`
    background: #8484e1;

    span {
      background: #6565bc;
    }
  `;

  const ButtonTwitter = styled(ButtonLink)`
    background: #37a8e5;

    span {
      background: #2792cc;
    }
  `;

  const ButtonLinkedin = styled(ButtonLink)`
    background: #6aa0d0;

    span {
      background: #598bb7;
    }
  `;

  const ButtonYoutube = styled(ButtonLink)`
    background: #eb5358;

    span {
      background: #de4045;
      line-height: 54px;
    }
  `;

  return (
    <>
      <ProfileHeader isLoading={true} />
      <ContentBio>
        <>
          <Skeleton count={5} />
        </>
      </ContentBio>
      <div style={{ padding: '15px', textAlign: 'center' }}>
        <>
          <Skeleton
            count={5}
            style={{ maxWidth: 1024, marginBottom: 15, lineHeight: 6 }}
          />
        </>
      </div>
    </>
  );
};

export default LoadingProfile;
