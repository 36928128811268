import 'firebase/auth';
import 'firebase/database';

import { Link } from 'react-router-dom';

import app from './config';

const signOut = () => app.auth().signOut().then(() => window.location.href='/');

export default signOut;
