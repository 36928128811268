import React, { useContext } from "react";
import { AuthContext } from "../../Firebase/context";
import HeaderNaoLogado from "./NaoLogado";
import HeaderLogado from "./Logado";

const Header: React.FC = () => {
  const { user } = useContext(AuthContext);

  return user ? <HeaderLogado /> : <HeaderNaoLogado />;
};

export default Header;
