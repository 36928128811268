import React from 'react';
import { Link } from 'react-router-dom';

import { Container, Content, Important } from './style';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import maoCards from '../../assets/img/banner.jpg';

const Home: React.FC = () => {
  return (
    <Container>
      <Header />
      <Content>
        <div className="box-left">
          <h1>
            Crie sua página,
            <br /> e organize suas redes
            <br /> sociais em <span>um só lugar</span>
          </h1>
          <p>
            O <strong>joga.la</strong> permite que os usuários criem seus perfis
            com o objetivo de organizar informações importantes, como email,
            WhatsApp, redes sociais, informações de pagamento como pix ou até
            mesmo links personalizados.
            <br />
            <br />
            <Important>
              <strong>
                Não perca tempo e garanta o melhor link para o seu perfil,
              </strong>{' '}
              ex: joga.la/seunome
            </Important>
          </p>
          <Link to="/register">
            <button className="btn-register">Cadastre-se</button>
          </Link>
        </div>
        <div className="box-right">
          <img src={maoCards} alt="Mão-card" />
        </div>
      </Content>
      <Footer />
    </Container>
  );
};

export default Home;
