import React from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import {
  Container,
  BoxContentProfile,
  BoxContentText,
  BoxContentProfileImage,
  TextCidade,
  TextViews
} from './style';
import ImageDefault from "../../assets/img/image.svg";

const ProfileHeader: React.FC = (props?: any) => {
  if(!props.profileData) return null;
  const BackgroundImage = styled(Container)`
    ${!props.isLoading && `background: url('${props.profileData.capa || ImageDefault}') no-repeat center`};
    background-size: cover;
  `;

  return (
    <BackgroundImage>
      <BoxContentProfile>
        <BoxContentText>
          <h1>
            {props.isLoading ? (
              <Skeleton style={{ width: 250 }} />
            ) : props.profileData.displayName || 'Fulano'}
          </h1>
          <TextCidade>
            {props.isLoading ? <Skeleton /> : `${props.profileData.localidade || 'Local não definido'} - ${props.profileData.uf }`}
          </TextCidade>
          <TextViews>
            {props.isLoading ? <Skeleton /> : props.profileData.status}
          </TextViews>
        </BoxContentText>
        <BoxContentProfileImage>
          {props.isLoading ? (
            <Skeleton style={{ width: 350, height: 350 }} />
          ) : <img src={props.profileData.perfil || ImageDefault} alt="" />}
        </BoxContentProfileImage>
      </BoxContentProfile>
    </BackgroundImage>
  );
};

export default ProfileHeader;
