import styled from 'styled-components';

export const Container = styled.div`
  display: block;
`;

export const BoxContentBio = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1024px;
  font-size: 18px;
  color: #000000;
  padding: 15px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  overflow: hidden;
  height: 160px;
  -moz-transition: height 1s ease;
  -webkit-transition: height 1s ease;
  -o-transition: height 1s ease;
  transition: height 1s ease;

  @media screen and (max-device-width: 480px) {
    font-size: 14px;
  }

  p {
    margin-bottom: 15px;
  }
`;

export const BoxLineContent = styled.div`
  display: block;
  max-width: 1024px;
  width: 100%;
  height: 16px;
  border-bottom: 1px solid #afafaf;
  margin: 20px auto;
  cursor: pointer;
`;

export const BoxContentLine = styled.div`
  display: block;
  border-radius: 20px;
  height: 30px;
  border: 1px solid #afafaf;
  text-align: center;
  background: #ffffff;
  max-width: 100px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 28px;
  vertical-align: middle;
  margin: 0 auto;
  cursor: pointer;
`;

export const TextAlert = styled.p`
  color: #e0a134;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  text-align: center;
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
`;

export const ButtonLink = styled.a`
  display: block;
  width: 100%;
  max-width: 1024px;
  height: 90px;
  line-height: 90px;
  vertical-align: middle;
  background: #442c75;
  border-radius: 10px;
  font-size: 40px;
  margin: 0 auto 15px auto;
  text-align: center;
  color: #ffffff;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  text-decoration: none;
  position: relative;


  @media screen and (max-device-width: 480px) {
    font-size: 28px;
    height: 60px;
    line-height: 60px;
  }

  span {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100px;
    text-align: center;
    background: #000000;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    line-height: 100px;
    vertical-align: middle;
    display: block;

    img {
      width: 44px;
    }

    @media screen and (max-device-width: 480px) {
      width: 60px;
      line-height: 68px;

      img {
        width: 28px;
      }
    }
  }

   &:hover {
        color: #fff;
      }
`;

export const ButtonPix = styled(ButtonLink)`
background: #30b6a8;
margin-top: -50px;

a {
  list-style: none;
  text-decoration: none;
  color: #fff;

  &:hover {
    color: #fff;
  }
}

.logo {
  background: #29ba98;
  display: flex;
  align-items: center;
  justify-content: center;
}

.div-copy {
  width: 88px;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 928px;
  z-index: 3;
  background: transparent;
  cursor: pointer;
}

.copy {
  width: 20px;
}

.copyed {
  font-size: 12px;
  background: transparent;
}
.text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  background-color: transparent;
  z-index: 1;
  cursor: pointer;

  &:hover {
    color: #fff;
  }
}

&::before {
  content: "";
  position: absolute;
  left: 0;
  margin-left: 5%;
  width: 0%;
  height: 100%;
  background: #29ba98;
  border-radius: 10px;
  transition: all 850ms;
}

&:hover {
  &::before {
    content: "";
    position: absolute;
    left: 0;
    width: 95%;
    height: 100%;
    background: #29ba98;
    border-radius: 10px;
    transition: all 850ms;
  }
}
`;

export const ContentBio = styled(BoxContentBio)`
${props => props.hideText ? "height: 160px" : "height: auto"};
-moz-transition: height 1s ease;
-webkit-transition: height 1s ease;
-o-transition: height 1s ease;
transition: height 1s ease;
`;

export const ButtonInsta = styled(ButtonLink)`
background: rgb(202, 101, 184);
background: linear-gradient(
  90deg,
  rgba(202, 101, 184, 1) 0%,
  rgba(202, 101, 184, 1) 100%
);

span {
  background: #d1707b;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  width: 100%;
  text-align: center;
  border-radius: 10px;
  background-color: transparent;
  z-index: 1;

  &:hover {
    color: #fff;
  }
}

&::before {
  content: "";
  position: absolute;
  left: 0;
  margin-left: 5%;
  width: 0%;
  height: 100%;
  background: #d1707b;
  border-radius: 10px;
  transition: all 850ms;
}

&:hover {
  &::before {
    content: "";
    position: absolute;
    left: 0;
    width: 95%;
    height: 100%;
    background: #d1707b;
    border-radius: 10px;
    transition: all 850ms;
  }
}
`;

export  const ButtonFace = styled(ButtonLink)`
background: rgba(72, 103, 170, 0.9);

span {
  background: #4867aa;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  width: 100%;
  text-align: center;
  border-radius: 10px;
  background-color: transparent;
  z-index: 1;

  &:hover {
    color: #fff;
  }
}

&::before {
  content: "";
  position: absolute;
  left: 0;
  margin-left: 5%;
  width: 0%;
  height: 100%;
  background: #4867aa;
  border-radius: 10px;
  transition: all 850ms;
}

&:hover {
  &::before {
    content: "";
    position: absolute;
    left: 0;
    width: 95%;
    height: 100%;
    background: #4867aa;
    border-radius: 10px;
    transition: all 850ms;
  }
}
`;

export const ButtonEmail = styled(ButtonLink)`
background: #4B4D4B;
cursor: pointer;

span {
  background: #2D312D;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  width: 100%;
  text-align: center;
  border-radius: 10px;
  background-color: transparent;
  z-index: 1;

  &:hover {
    color: #fff;
  }
}

&::before {
  content: "";
  position: absolute;
  left: 0;
  margin-left: 5%;
  width: 0%;
  height: 100%;
  background: #2D312D;
  border-radius: 10px;
  transition: all 850ms;
}

&:hover {
  &::before {
    content: "";
    position: absolute;
    left: 0;
    width: 95%;
    height: 100%;
    background: #2D312D;
    border-radius: 10px;
    transition: all 850ms;
  }
}
`;

export const ButtonTwitter = styled(ButtonLink)`
background: #37a8e5;

span {
  background: #2792cc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  width: 100%;
  text-align: center;
  border-radius: 10px;
  background-color: transparent;
  z-index: 1;

  &:hover {
    color: #fff;
  }
}

&::before {
  content: "";
  position: absolute;
  left: 0;
  margin-left: 5%;
  width: 0%;
  height: 100%;
  background: #2792cc;
  border-radius: 10px;
  transition: all 850ms;
}

&:hover {
  &::before {
    content: "";
    position: absolute;
    left: 0;
    width: 95%;
    height: 100%;
    background: #2792cc;
    border-radius: 10px;
    transition: all 850ms;
  }
}
`;

export const ButtonWhatsapp = styled(ButtonLink)`
background: #45D354;
cursor: pointer;

span {
  background: #3CBF4A;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  width: 100%;
  text-align: center;
  border-radius: 10px;
  background-color: transparent;
  z-index: 1;

  &:hover {
    color: #fff;
  }
}

&::before {
  content: "";
  position: absolute;
  left: 0;
  margin-left: 5%;
  width: 0%;
  height: 100%;
  background: #3CBF4A;
  border-radius: 10px;
  transition: all 850ms;
}

&:hover {
  &::before {
    content: "";
    position: absolute;
    left: 0;
    width: 95%;
    height: 100%;
    background: #3CBF4A;
    border-radius: 10px;
    transition: all 850ms;
  }
}
`;

export const ButtonPhone = styled(ButtonLink)`
background: #55AF74;
cursor: pointer;

span {
  background: #499A65;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  width: 100%;
  text-align: center;
  border-radius: 10px;
  background-color: transparent;
  z-index: 1;

  &:hover {
    color: #fff;
  }
}

&::before {
  content: "";
  position: absolute;
  left: 0;
  margin-left: 5%;
  width: 0%;
  height: 100%;
  background: #499A65;
  border-radius: 10px;
  transition: all 850ms;
}

&:hover {
  &::before {
    content: "";
    position: absolute;
    left: 0;
    width: 95%;
    height: 100%;
    background: #499A65;
    border-radius: 10px;
    transition: all 850ms;
  }
}
`;

export const ButtonLinkedin = styled(ButtonLink)`
background: #6aa0d0;

span {
  background: #598bb7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  width: 100%;
  text-align: center;
  border-radius: 10px;
  background-color: transparent;
  z-index: 1;

  &:hover {
    color: #fff;
  }
}

&::before {
  content: "";
  position: absolute;
  left: 0;
  margin-left: 5%;
  width: 0%;
  height: 100%;
  background: #598bb7;
  border-radius: 10px;
  transition: all 850ms;
}

&:hover {
  &::before {
    content: "";
    position: absolute;
    left: 0;
    width: 95%;
    height: 100%;
    background: #598bb7;
    border-radius: 10px;
    transition: all 850ms;
  }
}
`;

export const ButtonYoutube = styled(ButtonLink)`
background: #eb5358;

span {
  background: #de4045;
  line-height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  width: 100%;
  text-align: center;
  border-radius: 10px;
  background-color: transparent;
  z-index: 1;

  &:hover {
    color: #fff;
  }
}

&::before {
  content: "";
  position: absolute;
  left: 0;
  margin-left: 5%;
  width: 0%;
  height: 100%;
  background: #de4045;
  border-radius: 10px;
  transition: all 850ms;
}

&:hover {
  &::before {
    content: "";
    position: absolute;
    left: 0;
    width: 95%;
    height: 100%;
    background: #de4045;
    border-radius: 10px;
    transition: all 850ms;
  }
}
`;

export const ButtonMercadoLivre = styled(ButtonLink)`
background: #fff159;

span {
  background: #ffe600;
  line-height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  width: 100%;
  text-align: center;
  border-radius: 10px;
  background-color: transparent;
  z-index: 1;

  &:hover {
    color: #fff;
  }
}

&::before {
  content: "";
  position: absolute;
  left: 0;
  margin-left: 5%;
  width: 0%;
  height: 100%;
  background: #ffe600;
  border-radius: 10px;
  transition: all 850ms;
}

&:hover {
  &::before {
    content: "";
    position: absolute;
    left: 0;
    width: 95%;
    height: 100%;
    background: #ffe600;
    border-radius: 10px;
    transition: all 850ms;
  }
}
`;

export const ButtonIfood = styled(ButtonLink)`
background: #ea1d2c;

span {
  background: #cc1825;
  line-height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  width: 100%;
  text-align: center;
  border-radius: 10px;
  background-color: transparent;
  z-index: 1;

  &:hover {
    color: #fff;
  }
}

&::before {
  content: "";
  position: absolute;
  left: 0;
  margin-left: 5%;
  width: 0%;
  height: 100%;
  background: #cc1825;
  border-radius: 10px;
  transition: all 850ms;
}

&:hover {
  &::before {
    content: "";
    position: absolute;
    left: 0;
    width: 95%;
    height: 100%;
    background: #cc1825;
    border-radius: 10px;
    transition: all 850ms;
  }
}
`;