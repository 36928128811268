import { FC } from 'react';
import { TextField } from '@material-ui/core';
import InputMask from 'react-input-mask';
import lixo from '../../assets/img/lixo.svg';
import { BoxInput, NovoLink } from './style';

const style = {
  border: '1px dashed gray',
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white',
  cursor: 'move'
};

export interface CardProps {
  type: string;
  className: string;
  id: any;
  label: string;
  index: number;
  variant: any;
  name: string;
  custom: boolean;
  value: string;
  onChange?: Function;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const Card: FC<CardProps> = ({
  type,
  index,
  className,
  id,
  label,
  variant,
  name,
  custom,
  value,
  onChange
}) => {
  return (
    <>
      {custom ? (
        <NovoLink>
          <div className="btn-lixo">
            <img src={lixo} />
          </div>
          <div>
            <TextField
              type="text"
              className="inputAnimado"
              id="outlined-multiline-flexible"
              label="Nome"
              variant="filled"
              name="nome"
            />
            <TextField
              type="text"
              className="inputAnimado"
              id="outlined-multiline-flexible"
              label="Link"
              variant="filled"
              name="novoLink"
            />
          </div>
        </NovoLink>
      ) : (
        <BoxInput>
          {type === "tel" ? <InputMask mask="+5\5 (99) 99999-9999" value={value} onChange={onChange}>
            {() => <TextField
              type={type}
              className={'inputAnimado'}
              id={id}
              label={label}
              variant={'filled'}
              name={name}
            />}
          </InputMask> : 
          <TextField
            type={type}
            className={'inputAnimado'}
            id={id}
            label={label}
            variant={'filled'}
            name={name}
            value={value} 
            onChange={onChange}
          />
        }
        </BoxInput>
      )}
    </>
  );
};
