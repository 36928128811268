import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import Footer from '../../components/Footer';
import Header from "../../components/Header";
import ProfileHeader from "../../components/ProfileHeader";
import LoadingProfile from "./LoadingProfile";
import Insta from "../../assets/img/icon/insta.svg";
import Face from "../../assets/img/icon/face.svg";
import Linkedin from "../../assets/img/icon/linkedin.svg";
import Twitter from "../../assets/img/icon/twitter.svg";
import Youtube from "../../assets/img/icon/youtube.svg";
import IconEmail from "../../assets/img/icon/email.svg";
import Mercado from "../../assets/img/icon/Logo_MercadoLivre.svg";
import Ifood from "../../assets/img/icon/ifood-logo.svg";
import IconWhatsapp from "../../assets/img/icon/whatsapp.svg";
import IconPhone from "../../assets/img/icon/phone.svg";

import {
  Container,
  ButtonPhone,
  ButtonWhatsapp,
  ButtonEmail,
  ContentBio,
  ButtonInsta,
  ButtonLinkedin,
  ButtonFace,
  ButtonYoutube,
  ButtonMercadoLivre,
  ButtonIfood,
  ButtonTwitter
} from "./style";
import "../../layout/custom.css";

const db = firebase.firestore();

const PageProfile: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState<any>(null);
  let { id }: any = useParams();

  useEffect(() => {
    let isMounted = true;
  
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const docRef = db.collection('user').where('userName', '==', id);
        const querySnapshot = await docRef.get();
        if (isMounted && !querySnapshot.empty) {
          const doc = querySnapshot.docs[0];
          setUserData(doc.data());
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };
    
    fetchData();
  
    return () => {
      isMounted = false;
    };
  }, [id]);
  

  const parseNumber = (number: string) => {
    const num = number.replace(/[^0-9]/g, '');
    return parseInt(num);
  }

  const renderItem = (item: any) => {
    switch (item.name) {
      case 'email':
        return (
          <CopyToClipboard style={{ cursor: 'pointer' }} text={item.value} 
            onCopy={() => toast('Email copiado!', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            })}>
            <ButtonEmail>
              <span>
                <img src={IconEmail} alt="email" />
              </span>
              <span className="text">Email</span>
            </ButtonEmail>
          </CopyToClipboard>
        );
      case 'telefone':
        return (
          <ButtonPhone href={`tel:${item.value}`}>
            <span>
              <img src={IconPhone} alt="Telefone" />
            </span>
            <span className="text">Telefone</span>
          </ButtonPhone>
        );
      case 'whatsapp':
        return (
          <ButtonWhatsapp href={`https://wa.me/${parseNumber(item.value)}`}>
            <span>
              <img src={IconWhatsapp} alt="" />
            </span>
            <span className="text">Whatsapp</span>
          </ButtonWhatsapp>
        );
      case 'instagram':
        return (
          <ButtonInsta href={item.value}>
            <span>
              <img src={Insta} alt="" />
            </span>
            <span className="text">Instagram</span>
          </ButtonInsta>
        );
      case 'facebook':
        return (
          <ButtonFace href={item.value}>
            <span>
              <img src={Face} alt="" />
            </span>
            <span className="text">Facebook</span>
          </ButtonFace>
        );
      case 'twitter':
        return (
          <ButtonTwitter href={item.value}>
            <span>
              <img src={Twitter} alt="" />
            </span>
            <span className="text">Twitter</span>
          </ButtonTwitter>
        );
      case 'youtube':
        return (
          <ButtonYoutube href={item.value}>
            <span>
              <img src={Youtube} alt="" />
            </span>
            <span className="text">Youtube</span>
          </ButtonYoutube>
        );
      case 'mercado-livre':
        return (
          <ButtonMercadoLivre href={item.value}>
            <span>
              <img src={Mercado} alt="" />
            </span>
            <span className="text">Mercado Livre</span>
          </ButtonMercadoLivre>
        );
      case 'ifood':
        return (
          <ButtonIfood href={item.value}>
            <span>
              <img src={Ifood} alt="" />
            </span>
            <span className="text">Ifood</span>
          </ButtonIfood>
        );
      case 'linkedin':
        return (
          <ButtonLinkedin href={item.value}>
            <span>
              <img src={Linkedin} alt="" />
            </span>
            <span className="text">Linkedin</span>
          </ButtonLinkedin>
        );
      default:
        return null;
    }
  }

  return (
    <Container>
      <Header />
      {isLoading ? (
        <LoadingProfile />
      ) : (
        <>
          <ProfileHeader profileData={userData} />
          {userData?.bio && <ContentBio>{userData?.bio}</ContentBio>}
          <div style={{ padding: "15px", textAlign: "center" }}>
            {userData?.links?.map((item: any) => (
              <div key={item.position}>{item.value && renderItem(item)}</div>
            ))}
          </div>
        </>
      )}
      <Footer />
    </Container>
  );
};

export default PageProfile;
