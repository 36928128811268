import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .div-header{
    width: 100%;
    display: flex;
    align-items: flex-start;
  }

  #field-bio {
    height: 200px!important;
    overflow: auto!important;
  }
`;

export const BoxImageCape = styled.input`
  position: absolute;
  border: 1px solid #000;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  display: block;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
`;

export const ImageCapa = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
`;

export const IconEditImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
`;

export const BoxImagePerfil = styled.input`
  position: absolute;
  border: 1px solid #000;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  display: block;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
`;

export const ImagePerfil = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
`;

export const BoxInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
  width: 100%;
  border: none !important;
  position: relative;
  
  .btn-burguer {
    width: 38px;
    cursor: move;
    position: absolute;
    right: 261px;
    top: 11px;
    left: auto!important;

    > img {
      width: 35px;
      margin-left: 25px;
    }
  }

  .inputAnimado,
  .inputAnimadoMultilinha {
    width: 50%;
    height: 55px;
    background-color: rgba(230, 230, 230, 0.4);
    border: none !important;
    border-radius: 6px;
    outline: none !important;
    font-size: 12px;
    color: black !important;
    font-family: 'Open Sans', sans-serif;

    @media screen and (max-device-width: 480px) {
      width: 100%;
    }

    > label {
      color: #000;
    }

    > div::before {
      border: none;
    }

    > div::after {
      border: none;
    }
`;

export const Content = styled.div`
  flex: 1;
  width: 100%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .div-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin-top: 20px;
    text-align: center;
    width: 1024px;
    position: relative;

    > h1 {
      font-size: 35px;

      @media screen and (max-device-width: 480px) {
        font-size: 28px;
      }

      > span {
        color: #442c75;

        > span {
          color: #f8ce05;
        }
      }
    }

    > h3 {
      width: 60%;
      text-align: center;
      margin-top: 20px;
      font-size: 20px;
      font-weight: 400;
      font-family: 'Open Sans', sans-serif;
      color: #707070;

      @media screen and (max-device-width: 480px) {
        width: 98%;
        font-size: 18px;
      }
    }
    .foto-capa {
      width: 100%;
      max-width: 1024px;
      height: 432px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background-color: #e6e6e6;
      cursor: pointer;
      border: none;
      position: relative;

      ::before {
        content: ' ';
        display: block;
        background: #442c75;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0.8;
        z-index: 1;
      }

      > img.iconEdit {
        display: none;
        margin-right: 10px;
        margin-top: -380px;
        width: 35px;
        cursor: pointer;
        border: none;
        position: relative;
        z-index: 1;
      }

      &:hover {
        > img.iconEdit {
          display: flex;
        }
      }
    }

    .foto-perfil {
      position: relative;
      width: 350px;
      height: 350px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #e6e6e6;
      border: 2px solid #fff;
      margin-top: -80px;
      z-index: 5;

      > img.iconEdit {
        display: none;
        width: 35px;
        cursor: pointer;
        border: none;
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 1;
      }

      &:hover {
        > img.iconEdit {
          display: flex;
        }
      }
    }
  }

  .div-center {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    position: relative;

    @media screen and (max-device-width: 480px) {
      padding: 0 20px;
      margin-top: 10px;
    }

    .box-field {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 10px 0;
      width: 100%;
      border: none !important;

      .inputAnimado,
      .inputAnimadoMultilinha {
        width: 50%;
        height: 55px;
        background-color: #ffffff;
        border: none !important;
        border-radius: 6px;
        outline: none !important;
        font-size: 12px;
        color: black !important;
        font-family: 'Open Sans', sans-serif;

        @media screen and (max-device-width: 480px) {
          width: 100%;
        }

        > label {
          color: #000;
        }

        > div::before {
          border: none;
        }

        > div::after {
          border: none;
        }
      }

      .inputAnimadoMultilinha {
        height: 237px;
      }
    }

    .informcoes {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      > p {
        margin: 5px 30px;
      }

      > div {
        width: 110px;
        border-bottom: 1px solid #ccc;
      }
    }

    .novo-link {
      border: 1px solid #e6e6e6 !important;
      width: 515px;
      border-radius: 5px;
      padding: 20px 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 30px;

      .inputAnimado {
        flex: 1;
        width: 105%;
        margin: 5px;
        margin-left: 20px;
      }

      .btn-burguer {
        width: 38px;
        cursor: pointer;

        > img {
          width: 35px;
          margin-left: 25px;
        }
      }
    }

    .btn-lixo {
      position: absolute;
      top: 1276px;
      cursor: pointer;

      > img {
        width: 35px;
      }
    }

    .btn-burguer {
      left: 100px;
    }
  }

  .div-bottom {
    width: 50%;
    display: flex;
    align-items: center;
    padding-top: 15px;

    @media screen and (max-device-width: 480px) {
      width: 88%;
      margin: 0 auto;
    }

    .div-input-check {
    }

    > div p {
      font-size: 14px;
      font-weight: 400;
      margin-left: 10px;
      color: #707070;

      @media screen and (max-device-width: 480px) {
        font-size: 12px;
      }
    }
  }

  .btn-cadastrar {
    margin-top: 20px;
    width: 50%;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 30px;

    @media screen and (max-device-width: 480px) {
      width: 100%;
      box-sizing: border-box;
      padding: 0 20px;
      border: none;
    }

    > button {
      border: none;
      width: 100%;
      height: 60px;
      border-radius: 6px;
      background-color: #442c75;
      color: #fff;
      font-size: 28px;
      cursor: pointer;
      font-family: 'Open Sans', sans-serif;
    }
  }

  .sua-conta {
    margin-top: 20px;
    color: #707070;

    > p span {
      color: #707070;
      text-decoration: underline;
    }
  }

  .btn-register {
    width: 515px;
    font-size: 22px;
    font-family: 'Circular Std';
    font-weight: bold;
    color: #fff;
    background-color: #442c75;
    border: none;
    padding: 15px 75px;
    border-radius: 7px;
    margin-top: 40px;
    animation-direction: alternate;
    -webkit-animation-name: pulse;
    animation-name: pulse;
    animation: pulse 1.5s infinite;
    cursor: pointer;

    @media screen and (max-width: 1366px) {
      font-size: 21px;
      padding: 10px 45px;
    }
  }
`;

export const NovoLink = styled.div`
  border: 1px solid #e6e6e6 !important;
  width: 515px;
  border-radius: 5px;
  padding: 20px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  position: relative;

  .btn-lixo {
    position: absolute;
    top: -17px !important;
    cursor: pointer;
    left: 49%;

    > img {
      width: 35px;
    }
  }
  .inputAnimado,
  .inputAnimadoMultilinha {
    width: 50%;
    height: 55px;
    background-color: #ffffff;
    border: none !important;
    border-radius: 6px;
    outline: none !important;
    font-size: 12px;
    color: black !important;
    font-family: 'Open Sans', sans-serif;

    @media screen and (max-device-width: 480px) {
      width: 100%;
    }

    > label {
      color: #000;
    }

    > div::before {
      border: none;
    }

    > div::after {
      border: none;
    }
  }

  .inputAnimado {
    flex: 1;
    width: 105%;
    margin: 5px;
    margin-left: 20px;
  }

  .btn-burguer {
    width: 38px;
    cursor: move !important;

    > img {
      width: 35px;
      margin-left: 25px;
    }
  }

  .cuoVAR .div-center .box-field .inputAnimado > div,
  .MuiFilledInput-root.Mui-focused,
  .box-field .inputAnimado,
  body .MuiInputBase-root,
  .MuiFilledInput-root.Mui-focused {
    background-color: #f5f5f5;
  }
`;


export const FlipCardContainer = styled.div`
  background-color: transparent;
  perspective: 1000px;
  position: relative;
`;

export const FlipCard = styled.div`
  background-color: transparent;
  width: 340px;
  height: 340px;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  transform: rotateY(${props => (props.flipped ? '180deg' : '0deg')});
`;

export const FlipCardFront = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
`;

export const FlipCardBack = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;