import styled from 'styled-components';


export const Container = styled.div`
  flex: 1;
  max-width: 95%;
  width: ${props => props.className === 'full-width' ? "100%" : "1024px"};
  height: 100px;
  display: flex;
  align-self: center;
  justify-content: space-between;
  padding: 10px 0;
  margin: 0 auto;
  align-items: center;

  @media screen and (max-device-width: 1280px) {
    height: 120px;
  }
  @media screen and (max-device-width: 480px) {
    height: 80px;
    padding: 0 15px;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 4px 14px -9px rgba(0, 0, 0, 0.59);
    box-shadow: 0px 4px 14px -9px rgba(0, 0, 0, 0.59);
    max-width: 100% !important;
  }

  .voltar {
    display: flex;
    align-items: center;
    vertical-align: middle;

    >a img {
      width: 15px;
    }

    > p {
      margin: 0;
      margin-left: 10px;
      line-height: 80px;
      vertical-align: middle;

      a {
        color: #989898;
        text-decoration: none;
        color: #000;
      }
    }
  }
`;

export const Logo = styled.div`
  width: 100%;
  max-width: 120px;
  align-self: center;

  @media screen and (max-device-width: 480px) {
    max-width: 100px;
  }

  img {
    width: 100%;
  }
`;

export const Cadastro = styled.div`
  .btn-register,
  .btn-entrar {
    color: #989898;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: none;

    @media screen and (max-device-width: 480px) {
      font-size: 14px;
    }
  }
  .btn-entrar {
    margin-left: 43px;
    color: #fff;
    padding: 8px 34px;
    background-color: #442c75;
    border-radius: 5px;

    @media screen and (max-device-width: 480px) {
      margin-left: 15px;
    }
  }
`;

export const Profile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 9;
  min-height: 80px;

  .avatar {
    width: 50px;
    height: 50px;
    border: 2px solid #F8CE00;
    border-radius: 25px;
    padding: 1px;
  }
  > ul {
    width: 290px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 5px;
    left: -300px;
    z-index: 9;

    >span{
      width: 100%;
      color: #fff;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
    }
  }

  > ul li {
    cursor: pointer;
  }

  > ul li div {
    display: flex;
    align-items: center;
    justify-content: center;

    > p {
      font-size: 13px;
      font-family: 'OpenSans';
      color: #606060;
    }
  }
`;

export const BoxLogin = styled.div`
 width: 0;
 height: 200px;
 position: absolute;
 top: 0;
 right: 0;
 background-color: #442C75;
 overflow: hidden;
 text-overflow: ellipsis;
 transition: all 500ms ease;
 display: block;
 white-space: nowrap;

 .menu {
  width: 100%;
  height: 65%;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  
  justify-content: space-evenly ;

  .link{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: rgba(131, 109, 175);
    padding-right: 15px;
    margin: 1px 0;

    &:hover{
      background-color: #331D61;
    }

    >img{
      margin-left: 15px;
    }
  }

  .active{
      background-color: #331D61;
    }

  border: 2px solid rgba(131, 109, 175, 0.2);
 }
`;
