import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

import { toast } from 'react-toastify';

const db = firebase.firestore();
const storageRef = firebase.storage().ref();

const uploadImagePerfil = (file, uid) => {
  console.log(file);

  var metadata = {
    contentType: 'image/jpeg'
  };

  fetch(file)
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      var uploadTask = storageRef
        .child(`perfil/${uid}/capa.jpg`)
        .put(blob, metadata);

      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        function (snapshot) {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log('Upload is paused');
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log('Upload is running');
              break;
          }
        },
        function (error) {
          switch (error.code) {
            case 'storage/unauthorized':
              console.log('storage/unauthorized');
              break;

            case 'storage/canceled':
              console.log('storage/canceled');
              break;

            case 'storage/unknown':
              console.log('storage/unknown');
              break;
          }
        },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            db.collection('user')
              .doc(uid)
              .update({
                perfil: downloadURL
              })
              .then(() => {
                toast('🎉  Imagem de perfil alterada!', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined
                });
              })
              .catch((error) => {
                toast.error('😬  Ops, algo deu errado...', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined
                });
              });

            console.log('File available at', downloadURL);
          });
        }
      );
    });

  // Upload file and metadata to the object 'images/mountains.jpg'
  // var uploadTask = storageRef.child(`capa/${uid}/capa.jpg`).put(file, metadata);

  // // Listen for state changes, errors, and completion of the upload.

  // storageRef
  //   .ref(`capa/${uid}/capa.jpg`)
  //   .put(file, metadata)
  //   .then(function (snapshot) {
  //     console.log('Uploaded a blob or file!');
  //   });

  // const uploadTask = storageRef.ref(`capa/${uid}/capa.jpg`).put(file);
  // uploadTask.on(
  //   'state_changed',
  //   (snapshot) => {
  //     const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  //     console.log('Upload is ' + progress + '% done');
  //   },
  //   (error) => {
  //     // Handle unsuccessful uploads
  //     console.log('error:-', error);
  //   },
  //   () => {
  //     // Handle successful uploads on complete
  //     // For instance, get the download URL: https://firebasestorage.googleapis.com/...
  //     uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
  //       console.log('File available at', downloadURL);
  //       // firestore.collection("All_Files").doc(uniId).set({
  //       //     file_name: file.name.toString(),
  //       //     id: uniId,
  //       //     download_url: downloadURL.toString()
  //       // })
  //       //     .then(() => {
  //       //         console.log("Document successfully written!");
  //       //     })
  //       //     .catch((error) => {
  //       //         console.error("Error writing document: ", error);
  //       //     });
  //     });
  //   }
  // );

  // firebase
  //   .auth()
  //   .signInWithEmailAndPassword(user.email, user.password)
  //   .then((userCredential) => {
  //     var profileComplete = userCredential.user?.uid;

  //     firebase
  //       .firestore()
  //       .collection('user')
  //       .doc(profileComplete)
  //       .get()
  //       .then((profile) => {
  //         var profileComp = profile.data()?.completeProfile;
  //         completeProfile = profileComp;
  //       })
  //       .then(
  //         () =>
  //           (window.location.href =
  //             completeProfile === true ? '/profile' : '/complete-profile')
  //       );

  //     var user = userCredential.user;
  //     toast('🎉  Bem-vindo ao joga.la!', {
  //       position: 'top-right',
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined
  //     });
  //   })
  //   .catch((error) => {
  //     var errorCode = error.code;
  //     var errorMessage = error.message;
  //     toast.error(`😬  Ops, ${errorMessage}`, {
  //       position: 'top-right',
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined
  //     });
  //     // ..
  //   });
};

export default uploadImagePerfil;
