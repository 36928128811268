import React, {
  FC,
  useState,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import { TextField, Tooltip } from "@material-ui/core";
import { Card } from "./Card";
import { UserContext } from "../../Firebase/context";
import { Alert } from "react-bootstrap";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import QRCode from 'qrcode';

const db = firebase.firestore();

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CropImageCape from "../../components/CropImageCape";
import CropImagePerfil from "../../components/CropImagePerfil";
import uploadImageCape from "../../Firebase/uploadImageCape";
import uploadImagePerfil from "../../Firebase/uploadImagePerfil";
import { getCroppedImg } from "../../components/CropImageCape/canvasUtils";
import { AuthContext } from "../../Firebase/context";
import editor from "../../assets/img/image-editor.svg";
import iconQrcode from "../../assets/img/qrcode.svg";

import {
  Container,
  Content,
  BoxImageCape,
  ImageCapa,
  BoxImagePerfil,
  FlipCardContainer, FlipCard, FlipCardFront, FlipCardBack
} from "./style";

export interface Item {
  position: number;
  type: string;
  className: string;
  id: any;
  label: string;
  index: number;
  variant: any;
  name: string;
  custom: boolean;
  value: string;
}
export interface ContainerState {
  cards: Item[];
}

type UserDataType = {
  bairro: string;
  bio: string;
  capa: string;
  cep: string;
  complemento: string;
  completeProfile: boolean;
  ddd: string;
  displayName: string;
  email: string;
  emailVerified: boolean;
  gia: string;
  ibge: string;
  localidade: string;
  logradouro: string;
  perfil: string;
  siafi: string;
  uf: string;
  uid: string;
  userName: string;
  zipCode: string;
}

const storage = firebase.storage();

export const Container2: FC = (props: any) => {
  const [data, setData] = useState(null);
  const { current }: any = useRef({ data, timer: null });
  const [cards, setCards] = useState([
    {
      position: 1,
      type: "tel",
      id: "label-telefone",
      label: "Telefone",
      name: "telefone",
      value: "",
    },
    {
      position: 2,
      type: "tel",
      id: "label-whatsapp",
      label: "Whatsapp",
      name: "whatsapp",
      value: "",
    },
    {
      position: 3,
      type: "email",
      id: "label-email",
      label: "E-mail",
      name: "email",
      value: "",
    },
    {
      position: 4,
      type: "url",
      id: "field-instagram",
      label: "Instagram",
      name: "instagram",
      value: "",
    },
    {
      position: 5,
      type: "url",
      id: "field-facebook",
      label: "Facebook",
      name: "facebook",
      value: "",
    },
    {
      position: 6,
      type: "url",
      id: "field-twitter",
      label: "Twitter",
      name: "twitter",
      value: "",
    },
    {
      position: 7,
      type: "url",
      id: "field-linkedin",
      label: "Linkedin",
      name: "linkedin",
      value: "",
    },
    {
      position: 8,
      type: "url",
      id: "field-youtube",
      label: "Youtube",
      name: "youtube",
      value: "",
    },
    {
      position: 9,
      type: "url",
      id: "field-ifood",
      label: "Ifood",
      name: "ifood",
      value: "",
    },
  ]);
  
  useEffect(() => {
    props.userData.links && setCards(props.userData.links);
    console.log(props.userData)
  }, []);

  const handleInput = (e:any) => {
    const newState = cards.map(obj => {
      // 👇️ if id equals 2, update country property
      if (obj.name === e.target.name) {
        return {...obj, value: e.target.value};
      }

      // 👇️ otherwise return object as is
      return obj;
    });
    props.savedUpdate('salvando');
    if (current.timer) clearTimeout(current.timer);

    current.timer = setTimeout(() => {
      current.timer = null;
      db.collection("user")
        .doc(props.uID)
        .update({
          links: newState,
        })
        .then(() => {
          props.savedUpdate('salvo');

          setTimeout(() => {
            props.savedUpdate(null);
          }, 3000);
        })
        .catch((error) => {
          setTimeout(() => {
            props.savedUpdate(null);
          }, 3000);
        });
      setData(current.data);
    }, 1000);
    setCards(newState);
  };

  const renderCard = (
    card: {
      id: number;
      text: string;
      type: string;
      className: string;
      label: string;
      variant: any;
      name: string;
      position: number;
      custom: boolean;
      value: string;
    },
    index: number
  ) => {
    return (
      <Card
        key={card.id}
        index={index}
        id={card.id}
        type={card.type}
        className={card.className}
        label={card.label}
        variant={card.variant}
        name={card.name}
        custom={card.custom}
        value={props?.userData?.links && props?.userData?.links[index]?.value || card.value}
        onChange={handleInput}
      />
    );
  };

  return (
    <>
      {cards.map((card: any, i) => renderCard(card, i))}
    </>
  );
};

const CompleteProfile: React.FC = () => {
  const [qrCodeUrl, setQRCodeUrl] = useState<string | null>(null);
  // image crop capa
  const [ImageCapeCrop, setImageCapeCrop]: any = useState({ x: 0, y: 0 });
  const [ImageCapeZoom, setImageCapeZoom]: any = useState(1);
  const [ImageCapeCroppedImage, setImageCapeCroppedImage]: any = useState(null);
  const [ImageCapeImageSrc, setImageCapeImageSrc]: any = React.useState(null);
  const [ImageCapeFileImage, setImageCapeFileImage]: any = React.useState(null);
  const [ImageCapeCroppedAreaPixels, setImageCapeCroppedAreaPixels]: any =
    useState(null);

  // image crop perfil
  const [ImagePerfilCrop, setImagePerfilCrop]: any = useState({ x: 0, y: 0 });
  const [ImagePerfilZoom, setImagePerfilZoom]: any = useState(1);
  const [ImagePerfilCroppedImage, setImagePerfilCroppedImage]: any =
    useState(null);
  const [ImagePerfilImageSrc, setImagePerfilImageSrc]: any =
    React.useState(null);
  const [ImagePerfilFileImage, setImagePerfilFileImage]: any =
    React.useState(null);
  const [ImagePerfilCroppedAreaPixels, setImagePerfilCroppedAreaPixels]: any =
    useState(null);
  const [data, setData] = useState(null);
  const [zipCode, setZipCode] = useState({});
  const { current }: any = useRef({ data, timer: null });
  const { user } = useContext(AuthContext);
  const { userData } = useContext(UserContext);
  const [statusSaved, setStatusSaved]: any = useState(null);
  const [isFlipped, setIsFlipped] = useState(false);
  const { uid } = user;

  // Fetch QR Code URL from Firebase Storage
  useEffect(() => {
    if (userData?.qrCodeUrl) {
      setQRCodeUrl(userData.qrCodeUrl);
    } else {
      // Fetch QR Code URL if not in userData
      const fetchQRCodeUrl = async () => {
        try {
          if(userData?.userName) {
            const profileUrl = `https://joga.la/${userData.userName}`;
            const qrCodeSvg = await QRCode.toString(profileUrl, { type: 'svg' });

            // Carregar o QR code no Firebase Storage
            const qrCodeRef = storage.ref(`qrcodes/${uid}.svg`);
            await qrCodeRef.putString(qrCodeSvg, 'raw', {
              contentType: 'image/svg+xml',
            });

            // Obter a URL do QR code armazenado
            const qrCodeUrl = await qrCodeRef.getDownloadURL();

            db.collection("user")
            .doc(uid)
            .update({
              qrCodeUrl,
            });
            
            setQRCodeUrl(qrCodeUrl);
          }
        } catch (error) {
          console.error("Erro ao buscar QR Code URL:", error);
        }
      };

      fetchQRCodeUrl();
    }
  }, [userData, uid]);

  // metodos de crop imagem capa
  const onFileChangeImageCape = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl: any = await readFile(file);

      setImageCapeFileImage(file);
      setImageCapeImageSrc(imageDataUrl);
    }
  };

  const hideImageCapeCrop = () => {
    setImageCapeImageSrc(null);
    setImageCapeFileImage(null);
  };

  const showCroppedImageCape = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        ImageCapeImageSrc,
        ImageCapeCroppedAreaPixels
      );
      uploadImageCape(croppedImage, uid);
      setImageCapeCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
    hideImageCapeCrop();
  }, [ImageCapeImageSrc, ImageCapeCroppedAreaPixels]);

  const onCropCompleteCape = useCallback(
    (croppedArea, croppedAreaPixels: any) => {
      setImageCapeCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  // metodos de crop imagem perfil
  const onFileChangeImagePerfil = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl: any = await readFile(file);

      setImagePerfilFileImage(file);
      setImagePerfilImageSrc(imageDataUrl);
    }
  };

  const hideImagePerfilCrop = () => {
    setImagePerfilImageSrc(null);
    setImagePerfilFileImage(null);
  };

  const showCroppedImagePerfil = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        ImagePerfilImageSrc,
        ImagePerfilCroppedAreaPixels
      );
      uploadImagePerfil(croppedImage, uid);
      setImagePerfilCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
    hideImagePerfilCrop();
  }, [ImagePerfilImageSrc, ImagePerfilCroppedAreaPixels]);

  const onCropCompletePerfil = useCallback(
    (croppedArea, croppedAreaPixels: any) => {
      setImagePerfilCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );
  
  const onChangeField: any = (e: any, type: any) => {
    current.data = { ...current.data, [e.target.name]: e.target.value };
    setStatusSaved('salvando');
    if (current.timer) clearTimeout(current.timer);

    current.timer = setTimeout(() => {
      current.timer = null;
      db.collection("user")
        .doc(uid)
        .update({
          [e.target.name]: e.target.value,
        })
        .then(() => {
          setStatusSaved('salvo');

          setTimeout(() => {
            setStatusSaved(null);
          }, 3000);
        })
        .catch((error) => {
          console.log("erro", error);
          setTimeout(() => {
            setStatusSaved(null);
          }, 3000);
        });
      setData(current.data);
    }, 1000);
  };
  
  const onChangeFieldZipCode = (e: any) => {
    setZipCode({
      ...zipCode,
      [e.target.value]: e.target.value
    });
    if(e.target.value.length > 7) {
      fetch(`https://viacep.com.br/ws/${e.target.value}/json/`)
      .then(response => {
        response.json()
          .then(data => {
            current.data = { ...current.data, [e.target.name]: e.target.value };

            if (current.timer) clearTimeout(current.timer);
            current.timer = setTimeout(() => {
              current.timer = null;
              db.collection("user")
                .doc(uid)
                .update({
                  [e.target.name]: e.target.value,
                  ...data
                })
                .then(() => {
                  console.log("salvo");
                })
                .catch((error) => {
                  console.log("erro", error);
                });
              setData(current.data);
            }, 1000);

            setZipCode({
              ...zipCode,
              ...data
            });
          })
      });
    }
  }

  const handleFlipClick = () => {
    setIsFlipped(!isFlipped);
  };

  return userData && (
    <Container>
      <div className="div-header">
        <Header />
      </div>
      <Alert variant={"warning"}>
        <strong>Mantenha seu perfil atualizado!</strong>
        <br />
        Você precisa completar seu perfil para ativar sua pagina, verifique os
        campos abaixo, adicione ou altere suas fotos de capa e de perfil :)
      </Alert>
      <CropImageCape
        imageSrc={ImageCapeImageSrc}
        hideImageCrop={hideImageCapeCrop}
        showCroppedImage={showCroppedImageCape}
        croppedImage={ImageCapeCroppedImage}
        onCropComplete={onCropCompleteCape}
        setCrop={setImageCapeCrop}
        setZoom={setImageCapeZoom}
        crop={ImageCapeCrop}
        zoom={ImageCapeZoom}
      />
      <CropImagePerfil
        imageSrc={ImagePerfilImageSrc}
        hideImageCrop={hideImagePerfilCrop}
        showCroppedImage={showCroppedImagePerfil}
        croppedImage={ImagePerfilCroppedImage}
        onCropComplete={onCropCompletePerfil}
        setCrop={setImagePerfilCrop}
        setZoom={setImagePerfilZoom}
        crop={ImagePerfilCrop}
        zoom={ImagePerfilZoom}
      />
      <Content>
        <div className="div-top">
          <div className="foto-capa">
            <img src={editor} className="iconEdit" />
            {!ImageCapeCroppedImage && userData?.capa && (
              <ImageCapa src={userData?.capa} />
            )}
            <BoxImageCape
              type="file"
              onChange={onFileChangeImageCape}
              accept="image/*"
            />
          </div>
          <div className="foto-perfil">
            
            <FlipCardContainer>
              <FlipCard flipped={isFlipped}>
                <FlipCardFront>
                  <img src={editor} className="iconEdit" />
                  {!ImagePerfilCroppedImage && userData?.perfil && (
                    <ImageCapa src={userData?.perfil} />
                  )}
                  {ImagePerfilCroppedImage && <ImageCapa src={ImagePerfilCroppedImage} />}
                  {!isFlipped && <BoxImagePerfil
                    type="file"
                    onChange={onFileChangeImagePerfil}
                    accept="image/*"
                  />}
                </FlipCardFront>
                <FlipCardBack>
                  {qrCodeUrl && <img src={qrCodeUrl} onClick={() => {
                    window.open(qrCodeUrl, '_blank');
                  }} alt="QR Code" style={{ width: '100%' }} />}
                </FlipCardBack>
              </FlipCard>
              {qrCodeUrl && <div onClick={handleFlipClick} style={{position: 'absolute', bottom: 10, right: 10, backgroundColor: '#442c75', cursor: 'pointer', padding: 10}}><img src={iconQrcode} alt="QR Code" style={{ width: 30, height: 30 }} /></div>}
            </FlipCardContainer>
          </div>
        </div>
        <div className="div-center">
          <div className="box-field">
            <TextField
              type="text"
              className="inputAnimado"
              id="field-name"
              label="Nome Completo"
              variant="filled"
              name="displayName"
              defaultValue={userData?.displayName}
              focused={userData?.displayName ? true : false}
              onChange={onChangeField}
            />
          </div>
          <div className="box-field">
            <Tooltip title="Uma frase que defina seu momento." placement="top">
              <TextField
                type="text"
                className="inputAnimado"
                id="field-name-status"
                label="Status"
                variant="filled"
                name="status"
                defaultValue={userData?.status}
                focused={userData?.status ? true : false}
                onChange={onChangeField}
              />
            </Tooltip>
          </div>
          <div className="box-field">
            <TextField
              type="number"
              className="inputAnimado"
              id="field-zipcode"
              label="Cep"
              variant="filled"
              name="zipCode"
              defaultValue={zipCode?.zipCode || userData?.zipCode}
              focused={zipCode?.zipCode || userData?.zipCode ? true : false}
              inputProps={{
                maxLength: 8
              }}
              onChange={onChangeFieldZipCode}
            />
          </div>
          <div className="box-field">
            <TextField
              type="text"
              className="inputAnimado"
              id="field-estado"
              label="Estado"
              variant="filled"
              name="uf"
              value={userData?.uf || zipCode?.uf}
              focused={userData?.uf || zipCode?.uf ? true : false}
              onChange={onChangeField}
            />
          </div>
          <div className="box-field">
            <TextField
              type="email"
              className="inputAnimado"
              id="field-cidade"
              label="Cidade"
              variant="filled"
              name="localidade"
              value={zipCode?.localidade || userData?.localidade}
              focused={zipCode?.localidade || userData?.localidade ? true : false}
              onChange={onChangeField}
            />
          </div>
          <div className="box-field">
            <TextField
              multiline
              className="inputAnimadoMultilinha"
              id="field-bio"
              label="Sobre você"
              variant="filled"
              name="bio"
              minRows={4}
              defaultValue={data?.bio || userData?.bio}
              focused={data?.bio || userData?.bio ? true : false}
              onChange={onChangeField}
            />
          </div>
          <div className="informcoes">
            <div />
            <p>INFORMAÇÕES PARA CONTATO</p>
            <div />
          </div>
          <Container2 savedUpdate={setStatusSaved} uID={uid} userData={userData} />
        </div>
      </Content>
      {statusSaved && <div style={statusSaved === 'salvando' ? {
        position: 'fixed',
        bottom: '30px',
        right: '30px',
        backgroundColor: '#dfdfdf',
        padding: '5px 10px',
        borderRadius: '5px',
        fontSize: '12px',
        opacity: 0.5
      } : {
        position: 'fixed',
        bottom: '30px',
        right: '30px',
        backgroundColor: '#09b14c',
        padding: '5px 10px',
        borderRadius: '5px',
        fontSize: '12px',
        opacity: 0.5,
        color: '#fff'
      }}>
        {statusSaved === 'salvando' ? 'Salvando...' : 'Salvo!'}
      </div>}
      <Footer />
    </Container>
  );
};

function readFile(file: any) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

export default CompleteProfile;
