import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Container } from './style';

const Footer: React.FC = () => {
  const url = useHistory().location.pathname;
  return (
    <Container style={url === '/' ? { position: 'absolute', bottom: 0 } : {}}>
      <ul>
        <li className="link">
          <Link to="/sobre">Sobre</Link>
        </li>
        <li className="link">
          <Link to="/privacidade">Privacidade</Link>
        </li>
        {/* <li className="link">
          <Link to="/como-funciona">Como Funciona</Link>
        </li>
        <li className="link">
          <Link to="/termos">Termos</Link>
        </li>
        <li className="link">
          <Link to="/lgpd">LGPD</Link>
        </li> */}
      </ul>
      <ToastContainer />
    </Container>
  );
};

export default Footer;
