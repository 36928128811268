import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

import { toast } from 'react-toastify';
import QRCode from 'qrcode'; // Biblioteca para gerar QR codes como base64

interface User {
  userName: string;
  email: string;
  password: string;
}

const db = firebase.firestore();
const storage = firebase.storage();

const createUser = async (user: User) => {
  try {
    const userCredential = await firebase
      .auth()
      .createUserWithEmailAndPassword(user.email, user.password);

    const registerUser = userCredential.user;
    const userUid = registerUser?.uid;
    const profileUrl = `https://joga.la/${user.userName}`;

    // Gerar o QR code como SVG usando a biblioteca 'qrcode'
    const qrCodeSvg = await QRCode.toString(profileUrl, { type: 'svg' });

    // Carregar o QR code no Firebase Storage
    const qrCodeRef = storage.ref(`qrcodes/${userUid}.svg`);
    await qrCodeRef.putString(qrCodeSvg, 'raw', {
      contentType: 'image/svg+xml',
    });

    // Obter a URL do QR code armazenado
    const qrCodeUrl = await qrCodeRef.getDownloadURL();

    const userRegister = {
      userName: user.userName,
      displayName: registerUser?.displayName,
      email: registerUser?.email,
      emailVerified: registerUser?.emailVerified,
      phoneNumber: registerUser?.phoneNumber,
      photoURL: registerUser?.photoURL,
      uid: registerUser?.uid,
      completeProfile: false,
      qrCodeUrl, // URL do QR code
    };

    if (userUid) {
      await db.collection('user').doc(userUid).set(userRegister);
      toast('🎉  Bem-vindo ao joga.la!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      window.location.href = '/complete-profile';
    }
  } catch (error) {
    const errorMessage = error;
    console.error(error);
    toast.error(errorMessage, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

export default createUser;
