import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 432px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-repeat: no-repeat;
  position: relative;
  max-width: 1024px;
  margin: 0 auto;

  @media screen and (max-device-width: 480px) {
    height: 160px;
    background-size: cover;
  }

  &:after {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: #442c75de;
    z-index: 0;
  }
`;

export const BoxContentProfile = styled.div`
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1;
  width: 100%;
`;

export const BoxContentText = styled.div`
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  padding-left: 40px;
  padding-right: 40px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  color: #ffffff;

  @media screen and (max-device-width: 480px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  h1 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 72px;
    color: #f8ce05;
    line-height: 68px;

    @media screen and (max-device-width: 480px) {
      font-size: 30px;
      line-height: 30px;
    }
  }
`;

export const TextCidade = styled.div`
  font-size: 28px;
  font-weight: 100;
  margin: 5px 0;

  @media screen and (max-device-width: 480px) {
    font-size: 18px;
    margin: 0;
  }
`;

export const TextViews = styled.div`
  font-weight: 600;
`;

export const BoxContentProfileImage = styled.div`
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  padding-right: 40px;
  max-width: 360px;
  max-height: 360px;
  overflow: hidden;

  img {
    width: 100%;
  }


  @media screen and (max-device-width: 480px) {
    padding-right: 20px;

    img {
      width: 100%;
      max-width: 130px;
    }
  }

  img {
    border-radius: 5px;
  }
`;
