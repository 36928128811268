import React from 'react';
import Cropper from 'react-easy-crop';
import Slider from '@material-ui/core/Slider';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { Container } from './style';

const CropImagePerfil = (props: any) => {
  return (
    <>
      {props.imageSrc ? (
        <Container>
          <React.Fragment>
            <div className="cropContainer">
              <Cropper
                image={props.imageSrc}
                crop={props.crop}
                zoom={props.zoom}
                aspect={3 / 3}
                onCropChange={props.setCrop}
                onCropComplete={props.onCropComplete}
                onZoomChange={props.setZoom}
              />
            </div>
            <div className={'controls'}>
              <Button
                onClick={() => props.hideImageCrop()}
                variant="contained"
                color="primary"
                className="buttonCancel"
              >
                Cancelar
              </Button>
              <div className={'sliderContainer'}>
                <Typography variant="overline">Zoom</Typography>
                <Slider
                  value={props.zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(_e, zoom) => props.setZoom(zoom)}
                />
              </div>
              <Button
                onClick={props.showCroppedImage}
                variant="contained"
                color="primary"
                className="buttonSave"
              >
                Salvar
              </Button>
            </div>
          </React.Fragment>
        </Container>
      ) : null}
    </>
  );
};

export default CropImagePerfil;
