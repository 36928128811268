import create from "zustand";
import { persist } from "zustand/middleware";

export const useStore = create(persist(
  (set, get) => ({
    isLoading: false,
    login: null,
    setLoading: (status: boolean) => set(() => ({ isLoading: status})),
    setLogin: (status: any) => set({ login: status }),
  }),
  {
    name: "jl-user-storage", // unique name
    getStorage: () => sessionStorage, // (optional) by default the 'localStorage' is used
  }
));

