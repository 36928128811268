import React, { useState, ChangeEvent, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { TextField, InputAdornment } from '@material-ui/core';
import firebase from 'firebase';
import { FirebaseAuth } from 'react-firebaseui';
import { AuthContext } from '../../Firebase/context';
import checkUserUrl from '../../Firebase/checkUserUrl';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Termos from '../../components/Termos';
import createUser from '../../Firebase/createUser';
import { Container, Content } from './style';

const Register: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [showJoga, setShowJoga] = useState(false);
  const [terms, setTerms] = useState(false);
  const [loadingUser, setLoagingUser] = useState(false);
  const [validUser, setValidUser] = useState('');
  const [register, setRegister] = useState<any>(null);
  const { user } = useContext(AuthContext);

  const handleChange = () => {
    setTerms(!terms);
  };

  const handleModalShow = () => {
    setShowModal(!showModal);
  };

  function handleBlurFocus() {
    setShowJoga(false);
  }

  function handleFocus() {
    setShowJoga(true);
  }

  const hendleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    let dataRegister = { ...register, [event.target.name]: event.target.value };

    if (event.target.name === 'userName' && !loadingUser) {
      setLoagingUser(true);
      setTimeout(async () => {
        setLoagingUser(false);
        // setValidUser('valid');
        const result = await checkUserUrl(event.target.value);
        const { size } = result;
        if (size > 0) {
          setValidUser('invalid');
        } else {
          setValidUser('valid');
        }
      }, 5000);
    }
    setRegister(dataRegister);
  };

  const validateForm = () => {
    if (register) {
      !register?.userName &&
        toast.warn('Por favor preencha seu usuário 🙃 ', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });

      !register?.email &&
        toast.warn('📣 Por favor preencha seu email', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });

      !register?.password &&
        toast.warn('🔑 Por favor defina sua senha', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });

      !register?.confirmPassword &&
        toast.warn('🔑 Por favor confirme sua senha', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });

      if (
        register?.password &&
        register?.confirmPassword &&
        register?.password !== register?.confirmPassword
      ) {
        toast.warn('❌  As senhas não conferem', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      }

      !terms &&
        toast.warn('📄 Leia e aceite os termos', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
    } else {
      toast.warn('Por favor preencha os campos 🤷🏻‍♂️ ', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
  };

  const handleSaveRegister = () => {
    validateForm();

    if (
      register?.userName &&
      register?.email &&
      register?.password &&
      register?.confirmPassword &&
      register?.password === register?.confirmPassword &&
      terms
    ) {
      createUser(register);
    }
  };

  return !user ? (
    <Container>
      <Header />
      <Content>
        <div className="div-top">
          <h1>
            Crie sua conta{' '}
            <span>
              joga<span>.</span>la
            </span>
          </h1>
          <h3>
            Crie sua conta para configurar seu perfil no joga.la. Você receberá
            um email de confirmação para dar continuidade ao cadastro.
          </h3>
        </div>
        <div className="div-center">
          <div style={{ position: 'relative' }}>
            <TextField
              type="text"
              className="inputAnimado"
              id="standard-required"
              label="Usuário"
              variant="filled"
              InputProps={
                showJoga
                  ? {
                      startAdornment: (
                        <InputAdornment position="start">
                          joga.la/
                        </InputAdornment>
                      )
                    }
                  : {}
              }
              onBlur={handleBlurFocus}
              onClick={handleFocus}
              onChange={hendleChange}
              value={register ? register?.userName : undefined}
              name="userName"
            />
            {loadingUser && (
              <div style={{ position: 'absolute', right: '26.8%', top: '15px' }}>
                {'⏳'}
              </div>
            )}

            {!loadingUser && validUser === 'invalid' && (
              <div style={{ position: 'absolute', right: '26.8%', top: '15px' }}>
                {'🚫'}
              </div>
            )}

            {!loadingUser && validUser === 'valid' && (
              <div style={{ position: 'absolute', right: '26.8%', top: '15px' }}>
                {'✅'}
              </div>
            )}
          </div>
          <div>
            <TextField
              type="email"
              className="inputAnimado"
              id="outlined-multiline-flexible"
              label="Email"
              variant="filled"
              onChange={hendleChange}
              value={register ? register?.email : undefined}
              name="email"
            />
          </div>
          <div>
            <TextField
              className="inputAnimado"
              id="outlined-password-input"
              label="Senha"
              type="password"
              autoComplete="current-password"
              variant="filled"
              onChange={hendleChange}
              value={register ? register?.password : undefined}
              name="password"
            />
          </div>
          <div>
            <TextField
              className="inputAnimado"
              id="outlined-password-input"
              label="Confirmar Senha"
              type="password"
              autoComplete="current-password"
              variant="filled"
              onChange={hendleChange}
              value={register ? register?.confirmPassword : undefined}
              name="confirmPassword"
            />
          </div>
        </div>
        <div className="div-bottom">
          <div className="div-input-check">
            <input type="checkbox" checked={terms} onChange={handleChange} />
          </div>
          <div>
            <p onClick={() => handleModalShow()}>
              Ao criar uma conta, você concorda com nossos Termos, Condições e
              Política de Privacidade
            </p>
          </div>
        </div>
        <div className="btn-cadastrar">
          <button onClick={handleSaveRegister}>CADASTRAR</button>
        </div>
        <div className="sua-conta">
          <p>
            Já possui uma conta?{' '}
            <Link to="/login">
              <span>Entre com sua conta.</span>
            </Link>
          </p>
        </div>
      </Content>
      <Termos propsshowModal={showModal} propssetShowModal={setShowModal} />
      <Footer />
    </Container>
  ) : (
    <Redirect to={{ pathname: '/complete-profile' }} />
  );
};

export default Register;
