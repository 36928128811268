import React, { useContext, useEffect, useState } from 'react';
import firebase from "firebase";
import app from './config';
import "firebase/auth";
import "firebase/database";

const db = firebase.firestore();

export const AuthContext = React.createContext();
export const UserContext = React.createContext({userData: null});

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    (async () => {
      if (user) {
        const currentUserDocument = await db.collection("user")
        const currentUserDocument2 = await currentUserDocument.doc(user?.uid).get();
        setUserData(currentUserDocument2.data());
      } else {
        setUserData(null)
      }
    })()
  }, [user])

  // useEffect(() => {
  //   const docRef = db.collection("user").doc(user?.uid);
  //   docRef
  //     .get()
  //     .then((doc) => {
  //       if (doc.exists) {
  //         console.log({userData: doc.data()})
  //         setUserData(doc.data());
  //       } else {
  //         console.log("No such document!");
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("Error getting document:", error);
  //     });

  // }, []);

  return (
    <UserContext.Provider value={{ userData }}>{children}</UserContext.Provider>
  );
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    app.auth().onAuthStateChanged(setUser);
  }, []);

  return (
    <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
  );
};
