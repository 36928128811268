import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .div-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin-top: 20px;
    text-align: center;

    > h1 {
      font-size: 35px;

      @media screen and (max-device-width: 480px) {
        font-size: 28px;
      }

      > span {
        color: #442c75;

        > span {
          color: #f8ce05;
        }
      }
    }

    > h3 {
      width: 60%;
      text-align: center;
      margin-top: 20px;
      font-size: 20px;
      font-weight: 400;
      font-family: 'Open Sans', sans-serif;
      color: #707070;

      @media screen and (max-device-width: 480px) {
        width: 98%;
        font-size: 18px;
      }
    }
  }

  .div-center {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    position: relative;

    @media screen and (max-device-width: 480px) {
      padding: 0 20px;
      margin-top: 10px;
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 10px 0;
      width: 100%;
      border: none !important;

      .inputAnimado {
        width: 50%;
        height: 55px;
        background-color: rgba(230, 230, 230, 0.4);
        border: none !important;
        border-radius: 6px;
        outline: none !important;
        font-size: 12px;
        color: black !important;

        @media screen and (max-device-width: 480px) {
          width: 100%;
        }

        > label {
          color: #000;
        }

        > div::before {
          border: none;
        }

        > div::after {
          border: none;
        }
      }
    }
  }

  .div-bottom {
    width: 50%;
    display: flex;
    align-items: center;
    padding-top: 15px;

    @media screen and (max-device-width: 480px) {
      width: 88%;
      margin: 0 auto;
    }

    .div-input-check {
    }

    > div p {
      font-size: 14px;
      font-weight: 400;
      margin-left: 10px;
      color: #707070;

      @media screen and (max-device-width: 480px) {
        font-size: 12px;
      }
    }
  }

  .btn-entrar {
    margin-top: 20px;
    width: 50%;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 10px;

    @media screen and (max-device-width: 480px) {
      width: 100%;
      box-sizing: border-box;
      padding: 0 20px;
      border: none;
    }

    > button {
      border: none;
      width: 50%;
      height: 60px;
      border-radius: 6px;
      background-color: #442c75;
      color: #fff;
      font-size: 28px;
      cursor: pointer;
      font-family: 'Open Sans', sans-serif;
    }
  }

  .sua-conta {
    margin-top: 20px;
    color: #707070;

    > p span {
      color: #707070;
      text-decoration: underline;
    }
  }

  .btn-firebase {
    width: 100%;
    max-width: 510px;
  }
`;
