import React, { useState, useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import firebase from 'firebase';
import { AuthContext } from '../../../Firebase/context';
import signOut from '../../../Firebase/signOut';
import 'firebase/auth';
import 'firebase/database';

const db = firebase.firestore();
import { Container, Logo, Profile, BoxLogin, ContentProfile } from './style';
import LogoJogala from '../../../assets/img/Logo.svg';
import avatar from '../../../assets/img/user-circle.svg';
import arrow from '../../../assets/img/arrow-dropdown.svg';

type UserDataType = {
  bairro: string;
  bio: string;
  capa: string;
  cep: string;
  complemento: string;
  completeProfile: boolean;
  ddd: string;
  displayName: string;
  email: string;
  emailVerified: boolean;
  gia: string;
  ibge: string;
  localidade: string;
  logradouro: string;
  perfil: string;
  siafi: string;
  uf: string;
  uid: string;
  userName: string;
  zipCode: string;
}

const HeaderLogado: React.FC = () => {
  const [menuProfile, setMenuProfile] = useState(false);
  const [userData, setUserData] = useState<UserDataType | null>(null);
  const { user } = useContext(AuthContext);
  const url = useHistory().location.pathname;

  useEffect(() => {
    var docRef = db.collection('user').doc(user?.uid);

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          setUserData(doc.data());
        } else {
          console.log('No such document!');
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error);
      });
  }, []);


  return (
    <Container className={url === '/' ? 'full-width' : ''}>
      <Logo>
          <Link to={'/'}>
            <img src={LogoJogala} alt="Logo" />
          </Link>
        </Logo>
        <Profile>
          <ContentProfile onClick={() => setMenuProfile(!menuProfile)}>
            <div>
              <p>{userData && userData?.displayName}</p>
              <img className="arrow" src={arrow} alt="arrow" />
            </div>
            <div>
              <img className="avatar" src={userData && userData?.perfil || avatar} alt="Avatar" />
            </div>
          </ContentProfile>
          <BoxLogin style={menuProfile ? { height: 200, zIndex: 2 } : {}}>
            <div>
              <ul>
                {userData?.userName && (
                  <li>
                    <Link to={`/${userData && userData?.userName}`}>Minha Pagina</Link>
                  </li>
                )}

                <li>
                  <Link to="/complete-profile">Minha Conta</Link>
                </li>

                <li className="li-sair">
                  <Link to="#" onClick={signOut}>
                    Sair
                  </Link>
                </li>
              </ul>
            </div>
          </BoxLogin>
        </Profile>
    </Container>
  );
};

export default HeaderLogado;
